import {getResponseObject, type RequestOptions} from '@plugins/http/httpTools';
import {type AxiosResponse} from 'axios';

export class LoaderState {
  public loading: boolean;
  public failed: boolean;

  constructor() {
    this.loading = false;
    this.failed = false;
  }

  public resetState(loading?: boolean): void {
    this.loading = loading || false;
    this.failed = false;
  }
}

export async function loadLoaderStateData<T>(
  state: LoaderState,
  apiCall: () => Promise<AxiosResponse<T>>,
  dataCallback?: (data: T) => Promise<void> | void,
  options?: RequestOptions): Promise<T | undefined> {
  return executeWithLoaderState(state, async () => {
    const data = await getResponseObject(apiCall, options);
    if (data && dataCallback) {
      await dataCallback(data);
    }

    return data;
  });
}

export async function executeWithLoaderState<T>(
  state: LoaderState,
  callable: () => Promise<T>): Promise<T | undefined> {
  if (state.loading) {
    return;
  }

  state.loading = true;
  state.failed = false;

  try {
    return await callable();
  } catch (e) {
    state.failed = true;

    throw e;
  } finally {
    state.loading = false;
  }
}
