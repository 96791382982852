<template>
  <span
    :data-trans-key="msg"
    v-text="t"/>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import {getCurrentLocale, useTranslator} from '../i18n';

export default defineComponent({
  name: 'Translated',
  props: {
    msg: {
      type: String,
      required: true,
    },
    props: Object,
    upper: {
      type: Boolean,
      default: false,
    },
    lower: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const translator = useTranslator();
    const currentLocale = getCurrentLocale();

    return {
      t: computed(() => {
        const value = translator.translate(props.msg, props.props, currentLocale.value);
        if (props.upper) {
          return value.toLocaleUpperCase();
        } else if (props.lower) {
          return value.toLocaleLowerCase();
        } else {
          return value;
        }
      }),
    };
  },
});
</script>
