/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Address } from './address';
// May contain unused imports in some cases
// @ts-ignore
import type { Contact } from './contact';

/**
 * Customer create request information
 * @export
 * @interface CustomerCreateRequest
 */
export interface CustomerCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'customerType': CustomerCreateRequestCustomerTypeEnum;
    /**
     * Company name
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'company': string;
    /**
     * 
     * @type {Address}
     * @memberof CustomerCreateRequest
     */
    'address': Address;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerCreateRequest
     */
    'mainContact': Contact;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerCreateRequest
     */
    'billingContact'?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerCreateRequest
     */
    'orderContact'?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerCreateRequest
     */
    'abuseContact'?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof CustomerCreateRequest
     */
    'supportContact'?: Contact;
    /**
     * EU VAT number
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'vatNumber': string;
    /**
     * Whether or not VAT should be reverse charged
     * @type {boolean}
     * @memberof CustomerCreateRequest
     */
    'vatReverseCharged'?: boolean;
    /**
     * Chamber of Commerce number
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'cocNumber': string;
    /**
     * IBAN
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'iban': string;
    /**
     * BIC
     * @type {string}
     * @memberof CustomerCreateRequest
     */
    'bic': string;
}

export const CustomerCreateRequestCustomerTypeEnum = {
    EndUser: 'end-user',
    Reseller: 'reseller'
} as const;

export type CustomerCreateRequestCustomerTypeEnum = typeof CustomerCreateRequestCustomerTypeEnum[keyof typeof CustomerCreateRequestCustomerTypeEnum];


