<template>
  <div class="separator">
    <div class="flex-fill"/>
    <div class="content">
      <Translated
        msg="general.or"
        upper/>
    </div>
    <div class="flex-fill"/>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {Translated},
});
</script>

<style scoped lang="scss">
@import 'src/assets/css/variables';

$separator-width: 1px;

.separator {
  display: flex;
  width: 100%;
  margin: map-get($spacers, 1) 0;

  > div {
    &:not(.content) {
      position: relative;

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - #{$separator-width * 0.5});
        background-color: black;
        height: $separator-width;
      }
    }

    &.content {
      margin: 0 map-get($spacers, 3);
      padding: 0 !important;

      span {
        text-align: center;
        font-weight: bolder;
      }
    }
  }
}
</style>
