/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ExtendedAccountAllOf
 */
export interface ExtendedAccountAllOf {
    /**
     * The current account status
     * @type {string}
     * @memberof ExtendedAccountAllOf
     */
    'status': ExtendedAccountAllOfStatusEnum;
    /**
     * Creation time of the account
     * @type {string}
     * @memberof ExtendedAccountAllOf
     */
    'created': string;
    /**
     * Last login of the account
     * @type {string}
     * @memberof ExtendedAccountAllOf
     */
    'lastLogin'?: string;
}

export const ExtendedAccountAllOfStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED',
    PendingActivation: 'PENDING_ACTIVATION'
} as const;

export type ExtendedAccountAllOfStatusEnum = typeof ExtendedAccountAllOfStatusEnum[keyof typeof ExtendedAccountAllOfStatusEnum];


