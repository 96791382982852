/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MsaDetails } from '../model';
/**
 * CustomerMsaApi - axios parameter creator
 * @export
 */
export const CustomerMsaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the signed customer MSA document
         * @param {string} customerId The customer ID
         * @param {string} msaId The MSA document ID
         * @param {DownloadCustomerMsaTypeEnum} type The document type to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerMsa: async (customerId: string, msaId: string, type: DownloadCustomerMsaTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('downloadCustomerMsa', 'customerId', customerId)
            // verify required parameter 'msaId' is not null or undefined
            assertParamExists('downloadCustomerMsa', 'msaId', msaId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('downloadCustomerMsa', 'type', type)
            const localVarPath = `/v1/customer/{customerId}/msa/{msaId}/download`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"msaId"}}`, encodeURIComponent(String(msaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve MSA details
         * @param {string} customerId The customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMsaDetails: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerMsaDetails', 'customerId', customerId)
            const localVarPath = `/v1/customer/{customerId}/msa`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerMsaApi - functional programming interface
 * @export
 */
export const CustomerMsaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerMsaApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the signed customer MSA document
         * @param {string} customerId The customer ID
         * @param {string} msaId The MSA document ID
         * @param {DownloadCustomerMsaTypeEnum} type The document type to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCustomerMsa(customerId: string, msaId: string, type: DownloadCustomerMsaTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCustomerMsa(customerId, msaId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMsaApi.downloadCustomerMsa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve MSA details
         * @param {string} customerId The customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerMsaDetails(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MsaDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerMsaDetails(customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerMsaApi.getCustomerMsaDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerMsaApi - factory interface
 * @export
 */
export const CustomerMsaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerMsaApiFp(configuration)
    return {
        /**
         * Retrieve the signed customer MSA document
         * @param {CustomerMsaApiDownloadCustomerMsaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerMsa(requestParameters: CustomerMsaApiDownloadCustomerMsaRequest, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.downloadCustomerMsa(requestParameters.customerId, requestParameters.msaId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve MSA details
         * @param {CustomerMsaApiGetCustomerMsaDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerMsaDetails(requestParameters: CustomerMsaApiGetCustomerMsaDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<MsaDetails>> {
            return localVarFp.getCustomerMsaDetails(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for downloadCustomerMsa operation in CustomerMsaApi.
 * @export
 * @interface CustomerMsaApiDownloadCustomerMsaRequest
 */
export interface CustomerMsaApiDownloadCustomerMsaRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerMsaApiDownloadCustomerMsa
     */
    readonly customerId: string

    /**
     * The MSA document ID
     * @type {string}
     * @memberof CustomerMsaApiDownloadCustomerMsa
     */
    readonly msaId: string

    /**
     * The document type to download
     * @type {'msa' | 'receipt'}
     * @memberof CustomerMsaApiDownloadCustomerMsa
     */
    readonly type: DownloadCustomerMsaTypeEnum
}

/**
 * Request parameters for getCustomerMsaDetails operation in CustomerMsaApi.
 * @export
 * @interface CustomerMsaApiGetCustomerMsaDetailsRequest
 */
export interface CustomerMsaApiGetCustomerMsaDetailsRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerMsaApiGetCustomerMsaDetails
     */
    readonly customerId: string
}

/**
 * CustomerMsaApi - object-oriented interface
 * @export
 * @class CustomerMsaApi
 * @extends {BaseAPI}
 */
export class CustomerMsaApi extends BaseAPI {
    /**
     * Retrieve the signed customer MSA document
     * @param {CustomerMsaApiDownloadCustomerMsaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMsaApi
     */
    public downloadCustomerMsa(requestParameters: CustomerMsaApiDownloadCustomerMsaRequest, options?: RawAxiosRequestConfig) {
        return CustomerMsaApiFp(this.configuration).downloadCustomerMsa(requestParameters.customerId, requestParameters.msaId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve MSA details
     * @param {CustomerMsaApiGetCustomerMsaDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerMsaApi
     */
    public getCustomerMsaDetails(requestParameters: CustomerMsaApiGetCustomerMsaDetailsRequest, options?: RawAxiosRequestConfig) {
        return CustomerMsaApiFp(this.configuration).getCustomerMsaDetails(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DownloadCustomerMsaTypeEnum = {
    Msa: 'msa',
    Receipt: 'receipt'
} as const;
export type DownloadCustomerMsaTypeEnum = typeof DownloadCustomerMsaTypeEnum[keyof typeof DownloadCustomerMsaTypeEnum];
