<template>
  <div>
    <div
      v-if="account !== null"
      class="wide-container">
      <PageTitle
        :props="{company: account.company}"
        title="dashboard.welcome"/>

      <p>
        <Translated msg="dashboard.welcome-text"/>
      </p>

      <LoyaltyPage v-if="loyaltyParticipant"/>
    </div>
    <div
      v-else
      class="small-container">
      <DataCard
        class="mb-2"
        header-class="bg-theme">
        <template #header>
          <FlexTextIcon icon="key">
            <Translated msg="auth.login"/>
          </FlexTextIcon>
        </template>

        <p>
          <Translated msg="auth.login-text"/>&nbsp;<Translated
            v-if="ssoEnabled"
            msg="auth.login-text-sso"
            :props="{company}"/>
        </p>

        <template v-if="ssoEnabled">
          <div class="d-flex justify-content-end">
            <LoadButton
              icon="sign-in-alt"
              label="auth.login-sso"
              :label-props="{company}"
              :loading="authState.loggingIn"
              type="button"
              @click="onSsoAuth"/>
          </div>

          <OrSeparator v-if="legacyLoginAvailable"/>
        </template>

        <form
          v-if="legacyLoginAvailable"
          class="auth-form"
          @submit="onAuth">
          <p v-if="ssoEnabled">
            <Translated
              msg="auth.login-text-legacy"
              :props="{company}"/>
          </p>

          <div
            v-show="authState.authFailure"
            class="alert alert-danger mb-2">
            <FlexTextIcon>
              <template #icon>
                <SimpleIcon icon="exclamation-triangle"/>
              </template>
              <Translated msg="auth.failed"/>
            </FlexTextIcon>
          </div>

          <ValidatedField
            api-error-path="username"
            :api-errors="authApiErrors"
            autocomplete="username"
            autofocus
            :disabled="authState.loggingIn"
            lazy
            name="email"
            placeholder="auth.username"
            type="email"/>
          <ValidatedField
            api-error-path="password"
            :api-errors="authApiErrors"
            autocomplete="current-password"
            :disabled="authState.loggingIn"
            lazy
            name="password"
            placeholder="auth.password"
            type="password"/>

          <div class="d-flex justify-content-between">
            <router-link
              class="btn btn-text"
              :to="{name: 'reset-password'}">
              <SimpleIcon icon="question-circle"/>
              <Translated msg="auth.forgot-password"/>
            </router-link>
            <LoadButton
              icon="sign-in-alt"
              label="auth.login"
              :loading="authState.loggingIn"
              type="submit"/>
          </div>
        </form>
      </DataCard>

      <DataCard
        class="mb-2"
        header-class="bg-theme">
        <template #header>
          <FlexTextIcon icon="signature">
            <Translated msg="apply.title.short"/>
          </FlexTextIcon>
        </template>

        <p>
          <Translated msg="apply.text.partner"/>
        </p>

        <div class="d-flex justify-content-end">
          <router-link
            class="btn btn-primary"
            :to="{name: 'apply'}">
            <SimpleIcon icon="hand-point-right"/>
            <Translated msg="apply.button.partner"/>
          </router-link>
        </div>
      </DataCard>
    </div>
  </div>
</template>

<script lang="ts">
import LoyaltyPage from '@/pages/account/LoyaltyPage.vue';
import {companyName} from '@/utils';
import FlexTextIcon from '@components/FlexTextIcon.vue';
import LoadButton from '@components/form/LoadButton.vue';
import ValidatedField from '@components/form/ValidatedField.vue';
import DataCard from '@components/layout/DataCard.vue';
import OrSeparator from '@components/layout/OrSeparator.vue';
import PageTitle from '@components/layout/PageTitle.vue';
import SimpleIcon from '@components/SimpleIcon.vue';
import {useApiErrors} from '@plugins/api/api';
import {useAuthenticator, useCurrentAccount} from '@plugins/auth/auth';
import {AppFeatures} from '@plugins/features/AppFeatures';
import {useFeatures} from '@plugins/features/features';
import Translated from '@plugins/i18n/components/Translated.vue';
import {isBefore} from 'date-fns';
import {useForm} from 'vee-validate';
import {defineComponent, reactive} from 'vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    DataCard,
    OrSeparator,
    LoyaltyPage,
    LoadButton,
    ValidatedField,
    SimpleIcon,
    FlexTextIcon,
    Translated,
    PageTitle,
  },
  setup() {
    const authenticator = useAuthenticator();
    const account = useCurrentAccount();
    const authApiErrors = useApiErrors();

    const authState = reactive({
      loggingIn: false,
      authFailure: false,
    });
    const {handleSubmit, values: loginValues} = useForm<{ email: string; password: string }>({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: {
        email: 'required|email',
        password: 'required',
      },
    });

    const onAuth = handleSubmit(async () => {
      authState.authFailure = false;
      authState.loggingIn = true;

      try {
        const result = await authenticator.tryAuthenticate({
          username: loginValues.email,
          password: loginValues.password,
        }, authApiErrors);

        if (!result) {
          authState.authFailure = true;
        }
      } catch {
        authState.authFailure = true;
      } finally {
        authState.loggingIn = false;
      }
    });

    return {
      account,
      authApiErrors,
      authState,
      onAuth,
      legacyLoginAvailable: isBefore(new Date(), new Date(2025, 0, 1)),
      onSsoAuth: async () => {
        authState.loggingIn = true;
        try {
          window.location.href = await authenticator.generateSsoAuthenticationRedirect();
        } catch {
          authState.authFailure = true;
          authState.loggingIn = false;
        }
      },
      loyaltyParticipant: authenticator.isLoyaltyParticipant,
      ssoEnabled: useFeatures().featureEnabled(AppFeatures.sso),
      company: companyName(),
    };
  },
});
</script>
