import {type ModalInterface} from '@plugins/modal/modal';
import axios from 'axios';

export const COMPANY_A2B = 'a2b';
export const COMPANY_DC1 = 'dc1';
export const COMPANY_101010 = '101010tv';
export const CompanyTypes = [COMPANY_A2B, COMPANY_DC1, COMPANY_101010];
export type CompanyType = typeof CompanyTypes[number];

export function isProd(): boolean {
  return import.meta.env.PROD;
}

export function isA2B(): boolean {
  return import.meta.env.VITE_COMPANY === COMPANY_A2B;
}

export function isDC1(): boolean {
  return import.meta.env.VITE_COMPANY === COMPANY_DC1;
}

export function is101010tv(): boolean {
  return import.meta.env.VITE_COMPANY === COMPANY_101010;
}

export function companyName(): string {
  switch (import.meta.env.VITE_COMPANY) {
    case COMPANY_A2B:
      return 'A2B Internet';
    case COMPANY_DC1:
      return 'DC1.AMSTERDAM';
    case COMPANY_101010:
      return '101010TV';
    default:
      return '';
  }
}

export function isHmr(): boolean {
  return !!import.meta.hot || false;
}

export async function determineAppVersion(modals: ModalInterface): Promise<void> {
  // Determine if there is another deployed version
  const appVersion = import.meta.env.VITE_GIT_HASH;
  const deployedVersion = ((await axios.get('/version.json')).data as { git: string }).git;

  if (appVersion !== deployedVersion) {
    // There is another version, ask for reload
    modals.confirmError({
      title: 'error.action-failed.title',
      content: 'error.action-failed.text',
      okCallback: () => {
        window.location.reload();

        // Do not close the modal
        return false;
      },
    });
  } else {
    // Show general error modal
    modals.generalError();
  }
}

export function countToTheme(count: number): string {
  if (count > 1) {
    return 'success';
  }

  if (count < 1) {
    return 'danger';
  }

  return 'warning';
}

export function removeFromArray<T>(array: Array<T>, matcher: (item: T) => boolean): void {
  const index = array.findIndex(matcher);
  if (index !== -1) {
    array.splice(index, 1);
  }
}
