/* tslint:disable */
/* eslint-disable */
/**
 * 101010 order API and account API
 * Handles order API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@101010.tv
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ConfirmActivationRequestResponse } from '../model';
// @ts-ignore
import type { CreateActivationRequest } from '../model';
// @ts-ignore
import type { CreateActivationResponse } from '../model';
// @ts-ignore
import type { GenerateActivationCodeRequest } from '../model';
// @ts-ignore
import type { GenerateActivationCodeResponse } from '../model';
// @ts-ignore
import type { UnconfirmedActivationCode } from '../model';
// @ts-ignore
import type { UnconfirmedActivationCodeRequest } from '../model';
/**
 * ActivationApi - axios parameter creator
 * @export
 */
export const ActivationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateAccount', 'id', id)
            const localVarPath = `/v1/account/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel an unconfirmed activation request code 
         * @param {number} id The account id
         * @param {string} activationCodeId The activation code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUnconfirmedActivationCode: async (id: number, activationCodeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelUnconfirmedActivationCode', 'id', id)
            // verify required parameter 'activationCodeId' is not null or undefined
            assertParamExists('cancelUnconfirmedActivationCode', 'activationCodeId', activationCodeId)
            const localVarPath = `/v1/account/{id}/unconfirmedActivationCode/{activationCodeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"activationCodeId"}}`, encodeURIComponent(String(activationCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} activationRequestId The activation request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActivationRequest: async (id: number, activationRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmActivationRequest', 'id', id)
            // verify required parameter 'activationRequestId' is not null or undefined
            assertParamExists('confirmActivationRequest', 'activationRequestId', activationRequestId)
            const localVarPath = `/v1/account/{id}/activation/{activationRequestId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"activationRequestId"}}`, encodeURIComponent(String(activationRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an unconfirmed activation request, for activation at a later point in time. 
         * @param {number} id The account id
         * @param {UnconfirmedActivationCodeRequest} [unconfirmedActivationCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnconfirmedActivationCode: async (id: number, unconfirmedActivationCodeRequest?: UnconfirmedActivationCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createUnconfirmedActivationCode', 'id', id)
            const localVarPath = `/v1/account/{id}/unconfirmedActivationCode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unconfirmedActivationCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dismiss an activation request
         * @param {number} id The account id
         * @param {string} activationRequestId The activation request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissActivationRequest: async (id: number, activationRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dismissActivationRequest', 'id', id)
            // verify required parameter 'activationRequestId' is not null or undefined
            assertParamExists('dismissActivationRequest', 'activationRequestId', activationRequestId)
            const localVarPath = `/v1/account/{id}/activation/{activationRequestId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"activationRequestId"}}`, encodeURIComponent(String(activationRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {GenerateActivationCodeRequest} [generateActivationCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateActivationCode: async (id: number, generateActivationCodeRequest?: GenerateActivationCodeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateActivationCode', 'id', id)
            const localVarPath = `/v1/account/{id}/activation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateActivationCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves an activation request for a given unconfirmed activation code
         * @param {number} id The account id
         * @param {string} activationCodeId The activation code
         * @param {CreateActivationRequest} [createActivationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationRequest: async (id: number, activationCodeId: string, createActivationRequest?: CreateActivationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivationRequest', 'id', id)
            // verify required parameter 'activationCodeId' is not null or undefined
            assertParamExists('getActivationRequest', 'activationCodeId', activationCodeId)
            const localVarPath = `/v1/account/{id}/unconfirmedActivationCode/{activationCodeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"activationCodeId"}}`, encodeURIComponent(String(activationCodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnconfirmedActivationCodes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUnconfirmedActivationCodes', 'id', id)
            const localVarPath = `/v1/account/{id}/unconfirmedActivationCode`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivationApi - functional programming interface
 * @export
 */
export const ActivationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.activateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancel an unconfirmed activation request code 
         * @param {number} id The account id
         * @param {string} activationCodeId The activation code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUnconfirmedActivationCode(id: number, activationCodeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUnconfirmedActivationCode(id, activationCodeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.cancelUnconfirmedActivationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} activationRequestId The activation request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmActivationRequest(id: number, activationRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmActivationRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmActivationRequest(id, activationRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.confirmActivationRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an unconfirmed activation request, for activation at a later point in time. 
         * @param {number} id The account id
         * @param {UnconfirmedActivationCodeRequest} [unconfirmedActivationCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnconfirmedActivationCode(id: number, unconfirmedActivationCodeRequest?: UnconfirmedActivationCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnconfirmedActivationCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnconfirmedActivationCode(id, unconfirmedActivationCodeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.createUnconfirmedActivationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Dismiss an activation request
         * @param {number} id The account id
         * @param {string} activationRequestId The activation request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismissActivationRequest(id: number, activationRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismissActivationRequest(id, activationRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.dismissActivationRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {GenerateActivationCodeRequest} [generateActivationCodeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateActivationCode(id: number, generateActivationCodeRequest?: GenerateActivationCodeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateActivationCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateActivationCode(id, generateActivationCodeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.generateActivationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves an activation request for a given unconfirmed activation code
         * @param {number} id The account id
         * @param {string} activationCodeId The activation code
         * @param {CreateActivationRequest} [createActivationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivationRequest(id: number, activationCodeId: string, createActivationRequest?: CreateActivationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivationRequest(id, activationCodeId, createActivationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.getActivationRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnconfirmedActivationCodes(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnconfirmedActivationCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnconfirmedActivationCodes(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivationApi.getUnconfirmedActivationCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivationApi - factory interface
 * @export
 */
export const ActivationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivationApiFp(configuration)
    return {
        /**
         * 
         * @param {ActivationApiActivateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount(requestParameters: ActivationApiActivateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.activateAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel an unconfirmed activation request code 
         * @param {ActivationApiCancelUnconfirmedActivationCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUnconfirmedActivationCode(requestParameters: ActivationApiCancelUnconfirmedActivationCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelUnconfirmedActivationCode(requestParameters.id, requestParameters.activationCodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivationApiConfirmActivationRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActivationRequest(requestParameters: ActivationApiConfirmActivationRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConfirmActivationRequestResponse> {
            return localVarFp.confirmActivationRequest(requestParameters.id, requestParameters.activationRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an unconfirmed activation request, for activation at a later point in time. 
         * @param {ActivationApiCreateUnconfirmedActivationCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnconfirmedActivationCode(requestParameters: ActivationApiCreateUnconfirmedActivationCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<UnconfirmedActivationCode> {
            return localVarFp.createUnconfirmedActivationCode(requestParameters.id, requestParameters.unconfirmedActivationCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Dismiss an activation request
         * @param {ActivationApiDismissActivationRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissActivationRequest(requestParameters: ActivationApiDismissActivationRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dismissActivationRequest(requestParameters.id, requestParameters.activationRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivationApiGenerateActivationCodeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateActivationCode(requestParameters: ActivationApiGenerateActivationCodeRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenerateActivationCodeResponse> {
            return localVarFp.generateActivationCode(requestParameters.id, requestParameters.generateActivationCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves an activation request for a given unconfirmed activation code
         * @param {ActivationApiGetActivationRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivationRequest(requestParameters: ActivationApiGetActivationRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateActivationResponse> {
            return localVarFp.getActivationRequest(requestParameters.id, requestParameters.activationCodeId, requestParameters.createActivationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivationApiGetUnconfirmedActivationCodesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnconfirmedActivationCodes(requestParameters: ActivationApiGetUnconfirmedActivationCodesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<UnconfirmedActivationCode>> {
            return localVarFp.getUnconfirmedActivationCodes(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateAccount operation in ActivationApi.
 * @export
 * @interface ActivationApiActivateAccountRequest
 */
export interface ActivationApiActivateAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiActivateAccount
     */
    readonly id: number
}

/**
 * Request parameters for cancelUnconfirmedActivationCode operation in ActivationApi.
 * @export
 * @interface ActivationApiCancelUnconfirmedActivationCodeRequest
 */
export interface ActivationApiCancelUnconfirmedActivationCodeRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiCancelUnconfirmedActivationCode
     */
    readonly id: number

    /**
     * The activation code
     * @type {string}
     * @memberof ActivationApiCancelUnconfirmedActivationCode
     */
    readonly activationCodeId: string
}

/**
 * Request parameters for confirmActivationRequest operation in ActivationApi.
 * @export
 * @interface ActivationApiConfirmActivationRequestRequest
 */
export interface ActivationApiConfirmActivationRequestRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiConfirmActivationRequest
     */
    readonly id: number

    /**
     * The activation request id
     * @type {string}
     * @memberof ActivationApiConfirmActivationRequest
     */
    readonly activationRequestId: string
}

/**
 * Request parameters for createUnconfirmedActivationCode operation in ActivationApi.
 * @export
 * @interface ActivationApiCreateUnconfirmedActivationCodeRequest
 */
export interface ActivationApiCreateUnconfirmedActivationCodeRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiCreateUnconfirmedActivationCode
     */
    readonly id: number

    /**
     * 
     * @type {UnconfirmedActivationCodeRequest}
     * @memberof ActivationApiCreateUnconfirmedActivationCode
     */
    readonly unconfirmedActivationCodeRequest?: UnconfirmedActivationCodeRequest
}

/**
 * Request parameters for dismissActivationRequest operation in ActivationApi.
 * @export
 * @interface ActivationApiDismissActivationRequestRequest
 */
export interface ActivationApiDismissActivationRequestRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiDismissActivationRequest
     */
    readonly id: number

    /**
     * The activation request id
     * @type {string}
     * @memberof ActivationApiDismissActivationRequest
     */
    readonly activationRequestId: string
}

/**
 * Request parameters for generateActivationCode operation in ActivationApi.
 * @export
 * @interface ActivationApiGenerateActivationCodeRequest
 */
export interface ActivationApiGenerateActivationCodeRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiGenerateActivationCode
     */
    readonly id: number

    /**
     * 
     * @type {GenerateActivationCodeRequest}
     * @memberof ActivationApiGenerateActivationCode
     */
    readonly generateActivationCodeRequest?: GenerateActivationCodeRequest
}

/**
 * Request parameters for getActivationRequest operation in ActivationApi.
 * @export
 * @interface ActivationApiGetActivationRequestRequest
 */
export interface ActivationApiGetActivationRequestRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiGetActivationRequest
     */
    readonly id: number

    /**
     * The activation code
     * @type {string}
     * @memberof ActivationApiGetActivationRequest
     */
    readonly activationCodeId: string

    /**
     * 
     * @type {CreateActivationRequest}
     * @memberof ActivationApiGetActivationRequest
     */
    readonly createActivationRequest?: CreateActivationRequest
}

/**
 * Request parameters for getUnconfirmedActivationCodes operation in ActivationApi.
 * @export
 * @interface ActivationApiGetUnconfirmedActivationCodesRequest
 */
export interface ActivationApiGetUnconfirmedActivationCodesRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ActivationApiGetUnconfirmedActivationCodes
     */
    readonly id: number
}

/**
 * ActivationApi - object-oriented interface
 * @export
 * @class ActivationApi
 * @extends {BaseAPI}
 */
export class ActivationApi extends BaseAPI {
    /**
     * 
     * @param {ActivationApiActivateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public activateAccount(requestParameters: ActivationApiActivateAccountRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).activateAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel an unconfirmed activation request code 
     * @param {ActivationApiCancelUnconfirmedActivationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public cancelUnconfirmedActivationCode(requestParameters: ActivationApiCancelUnconfirmedActivationCodeRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).cancelUnconfirmedActivationCode(requestParameters.id, requestParameters.activationCodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivationApiConfirmActivationRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public confirmActivationRequest(requestParameters: ActivationApiConfirmActivationRequestRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).confirmActivationRequest(requestParameters.id, requestParameters.activationRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an unconfirmed activation request, for activation at a later point in time. 
     * @param {ActivationApiCreateUnconfirmedActivationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public createUnconfirmedActivationCode(requestParameters: ActivationApiCreateUnconfirmedActivationCodeRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).createUnconfirmedActivationCode(requestParameters.id, requestParameters.unconfirmedActivationCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dismiss an activation request
     * @param {ActivationApiDismissActivationRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public dismissActivationRequest(requestParameters: ActivationApiDismissActivationRequestRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).dismissActivationRequest(requestParameters.id, requestParameters.activationRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivationApiGenerateActivationCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public generateActivationCode(requestParameters: ActivationApiGenerateActivationCodeRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).generateActivationCode(requestParameters.id, requestParameters.generateActivationCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves an activation request for a given unconfirmed activation code
     * @param {ActivationApiGetActivationRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public getActivationRequest(requestParameters: ActivationApiGetActivationRequestRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).getActivationRequest(requestParameters.id, requestParameters.activationCodeId, requestParameters.createActivationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivationApiGetUnconfirmedActivationCodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public getUnconfirmedActivationCodes(requestParameters: ActivationApiGetUnconfirmedActivationCodesRequest, options?: RawAxiosRequestConfig) {
        return ActivationApiFp(this.configuration).getUnconfirmedActivationCodes(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

