<template>
  <div
    v-show="processedErrors.length > 0"
    class="validation-messages">
    <div
      v-for="error in processedErrors"
      :key="`vm-${error.msg}-${JSON.stringify(error.props)}`"
      class="text-danger">
      <Translated
        v-if="!noTrans"
        :msg="error.msg"
        :props="error.props"/>
      <span v-else>{{ error.msg }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  components: {Translated},
  props: {
    errors: {
      type: [String, Array],
      required: true,
    },
    noTrans: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const processedErrors = computed(() => {
      const errors: string[] = (Array.isArray(props.errors) ? props.errors : [props.errors]) as string[];
      return errors.map((e: string) => {
        const parts = e.split('$$');

        return {
          msg: parts[0],
          props: parts.length > 1 ? JSON.parse(parts[1]) : undefined,
        };
      });
    });

    return {
      processedErrors,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/assets/css/variables';

.validation-messages {
  margin-top: map-get($spacers, 1);
  padding: 0 $input-padding-x;
}
</style>
