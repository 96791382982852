/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Account } from '../model';
// @ts-ignore
import type { AccountActivationRequest } from '../model';
// @ts-ignore
import type { AccountCreateRequest } from '../model';
// @ts-ignore
import type { AccountPasswordResetKey } from '../model';
// @ts-ignore
import type { AccountPasswordResetRequest } from '../model';
// @ts-ignore
import type { BadRequest } from '../model';
// @ts-ignore
import type { ExtendedAccount } from '../model';
// @ts-ignore
import type { PasswordChangeRequest } from '../model';
// @ts-ignore
import type { UpdateCustomerAccountRequest } from '../model';
/**
 * CustomerAccountApi - axios parameter creator
 * @export
 */
export const CustomerAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activate a new user account
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {AccountActivationRequest} accountActivationRequest Activation information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount: async (customerId: string, accountId: string, accountActivationRequest: AccountActivationRequest, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('activateAccount', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('activateAccount', 'accountId', accountId)
            // verify required parameter 'accountActivationRequest' is not null or undefined
            assertParamExists('activateAccount', 'accountActivationRequest', accountActivationRequest)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/activate`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountActivationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the password of the customer account. When the reset is for the current account, the current password must be provided as well.
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {PasswordChangeRequest} passwordChangeRequest The password change request
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountPassword: async (customerId: string, accountId: string, passwordChangeRequest: PasswordChangeRequest, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('changeAccountPassword', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('changeAccountPassword', 'accountId', accountId)
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('changeAccountPassword', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user account
         * @param {string} customerId The customer ID
         * @param {AccountCreateRequest} accountCreateRequest Customer information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (customerId: string, accountCreateRequest: AccountCreateRequest, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('createAccount', 'customerId', customerId)
            // verify required parameter 'accountCreateRequest' is not null or undefined
            assertParamExists('createAccount', 'accountCreateRequest', accountCreateRequest)
            const localVarPath = `/v1/customer/{customerId}/account`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a customer account
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (customerId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteAccount', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteAccount', 'accountId', accountId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve account information
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (customerId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccount', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccount', 'accountId', accountId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the accounts for the customer
         * @param {string} customerId The customer ID
         * @param {boolean} [all] When set, return all accounts instead of online the active account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (customerId: string, all?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getAccounts', 'customerId', customerId)
            const localVarPath = `/v1/customer/{customerId}/account`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request a new password for the account with the supplied email address
         * @param {AccountPasswordResetRequest} accountPasswordResetRequest The information required to request the password reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset: async (accountPasswordResetRequest: AccountPasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountPasswordResetRequest' is not null or undefined
            assertParamExists('requestPasswordReset', 'accountPasswordResetRequest', accountPasswordResetRequest)
            const localVarPath = `/v1/customer/account/reset/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPasswordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset a customer account password with the information retrieved from the password reset email
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {AccountPasswordResetKey} accountPasswordResetKey The information required to reset the password
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAccountPassword: async (customerId: string, accountId: string, accountPasswordResetKey: AccountPasswordResetKey, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('resetAccountPassword', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('resetAccountPassword', 'accountId', accountId)
            // verify required parameter 'accountPasswordResetKey' is not null or undefined
            assertParamExists('resetAccountPassword', 'accountPasswordResetKey', accountPasswordResetKey)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/reset/password`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountPasswordResetKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the customer account status and roles
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {UpdateCustomerAccountRequest} updateCustomerAccountRequest The update request
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAccount: async (customerId: string, accountId: string, updateCustomerAccountRequest: UpdateCustomerAccountRequest, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerAccount', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('updateCustomerAccount', 'accountId', accountId)
            // verify required parameter 'updateCustomerAccountRequest' is not null or undefined
            assertParamExists('updateCustomerAccount', 'updateCustomerAccountRequest', updateCustomerAccountRequest)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAccountApi - functional programming interface
 * @export
 */
export const CustomerAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Activate a new user account
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {AccountActivationRequest} accountActivationRequest Activation information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAccount(customerId: string, accountId: string, accountActivationRequest: AccountActivationRequest, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAccount(customerId, accountId, accountActivationRequest, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.activateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Change the password of the customer account. When the reset is for the current account, the current password must be provided as well.
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {PasswordChangeRequest} passwordChangeRequest The password change request
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountPassword(customerId: string, accountId: string, passwordChangeRequest: PasswordChangeRequest, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccountPassword(customerId, accountId, passwordChangeRequest, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.changeAccountPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new user account
         * @param {string} customerId The customer ID
         * @param {AccountCreateRequest} accountCreateRequest Customer information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(customerId: string, accountCreateRequest: AccountCreateRequest, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(customerId, accountCreateRequest, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a customer account
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(customerId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(customerId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.deleteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve account information
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(customerId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(customerId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.getAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the accounts for the customer
         * @param {string} customerId The customer ID
         * @param {boolean} [all] When set, return all accounts instead of online the active account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(customerId: string, all?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendedAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(customerId, all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.getAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request a new password for the account with the supplied email address
         * @param {AccountPasswordResetRequest} accountPasswordResetRequest The information required to request the password reset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPasswordReset(accountPasswordResetRequest: AccountPasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPasswordReset(accountPasswordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.requestPasswordReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset a customer account password with the information retrieved from the password reset email
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {AccountPasswordResetKey} accountPasswordResetKey The information required to reset the password
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetAccountPassword(customerId: string, accountId: string, accountPasswordResetKey: AccountPasswordResetKey, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetAccountPassword(customerId, accountId, accountPasswordResetKey, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.resetAccountPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the customer account status and roles
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {UpdateCustomerAccountRequest} updateCustomerAccountRequest The update request
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerAccount(customerId: string, accountId: string, updateCustomerAccountRequest: UpdateCustomerAccountRequest, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerAccount(customerId, accountId, updateCustomerAccountRequest, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountApi.updateCustomerAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerAccountApi - factory interface
 * @export
 */
export const CustomerAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAccountApiFp(configuration)
    return {
        /**
         * Activate a new user account
         * @param {CustomerAccountApiActivateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount(requestParameters: CustomerAccountApiActivateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.activateAccount(requestParameters.customerId, requestParameters.accountId, requestParameters.accountActivationRequest, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the password of the customer account. When the reset is for the current account, the current password must be provided as well.
         * @param {CustomerAccountApiChangeAccountPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountPassword(requestParameters: CustomerAccountApiChangeAccountPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.changeAccountPassword(requestParameters.customerId, requestParameters.accountId, requestParameters.passwordChangeRequest, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user account
         * @param {CustomerAccountApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: CustomerAccountApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExtendedAccount> {
            return localVarFp.createAccount(requestParameters.customerId, requestParameters.accountCreateRequest, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a customer account
         * @param {CustomerAccountApiDeleteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(requestParameters: CustomerAccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAccount(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve account information
         * @param {CustomerAccountApiGetAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(requestParameters: CustomerAccountApiGetAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExtendedAccount> {
            return localVarFp.getAccount(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the accounts for the customer
         * @param {CustomerAccountApiGetAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(requestParameters: CustomerAccountApiGetAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ExtendedAccount>> {
            return localVarFp.getAccounts(requestParameters.customerId, requestParameters.all, options).then((request) => request(axios, basePath));
        },
        /**
         * Request a new password for the account with the supplied email address
         * @param {CustomerAccountApiRequestPasswordResetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordReset(requestParameters: CustomerAccountApiRequestPasswordResetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.requestPasswordReset(requestParameters.accountPasswordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset a customer account password with the information retrieved from the password reset email
         * @param {CustomerAccountApiResetAccountPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetAccountPassword(requestParameters: CustomerAccountApiResetAccountPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetAccountPassword(requestParameters.customerId, requestParameters.accountId, requestParameters.accountPasswordResetKey, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the customer account status and roles
         * @param {CustomerAccountApiUpdateCustomerAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAccount(requestParameters: CustomerAccountApiUpdateCustomerAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExtendedAccount> {
            return localVarFp.updateCustomerAccount(requestParameters.customerId, requestParameters.accountId, requestParameters.updateCustomerAccountRequest, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateAccount operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiActivateAccountRequest
 */
export interface CustomerAccountApiActivateAccountRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiActivateAccount
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiActivateAccount
     */
    readonly accountId: string

    /**
     * Activation information
     * @type {AccountActivationRequest}
     * @memberof CustomerAccountApiActivateAccount
     */
    readonly accountActivationRequest: AccountActivationRequest

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerAccountApiActivateAccount
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for changeAccountPassword operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiChangeAccountPasswordRequest
 */
export interface CustomerAccountApiChangeAccountPasswordRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiChangeAccountPassword
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiChangeAccountPassword
     */
    readonly accountId: string

    /**
     * The password change request
     * @type {PasswordChangeRequest}
     * @memberof CustomerAccountApiChangeAccountPassword
     */
    readonly passwordChangeRequest: PasswordChangeRequest

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerAccountApiChangeAccountPassword
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for createAccount operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiCreateAccountRequest
 */
export interface CustomerAccountApiCreateAccountRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiCreateAccount
     */
    readonly customerId: string

    /**
     * Customer information
     * @type {AccountCreateRequest}
     * @memberof CustomerAccountApiCreateAccount
     */
    readonly accountCreateRequest: AccountCreateRequest

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerAccountApiCreateAccount
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for deleteAccount operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiDeleteAccountRequest
 */
export interface CustomerAccountApiDeleteAccountRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiDeleteAccount
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiDeleteAccount
     */
    readonly accountId: string
}

/**
 * Request parameters for getAccount operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiGetAccountRequest
 */
export interface CustomerAccountApiGetAccountRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiGetAccount
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiGetAccount
     */
    readonly accountId: string
}

/**
 * Request parameters for getAccounts operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiGetAccountsRequest
 */
export interface CustomerAccountApiGetAccountsRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiGetAccounts
     */
    readonly customerId: string

    /**
     * When set, return all accounts instead of online the active account
     * @type {boolean}
     * @memberof CustomerAccountApiGetAccounts
     */
    readonly all?: boolean
}

/**
 * Request parameters for requestPasswordReset operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiRequestPasswordResetRequest
 */
export interface CustomerAccountApiRequestPasswordResetRequest {
    /**
     * The information required to request the password reset
     * @type {AccountPasswordResetRequest}
     * @memberof CustomerAccountApiRequestPasswordReset
     */
    readonly accountPasswordResetRequest: AccountPasswordResetRequest
}

/**
 * Request parameters for resetAccountPassword operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiResetAccountPasswordRequest
 */
export interface CustomerAccountApiResetAccountPasswordRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiResetAccountPassword
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiResetAccountPassword
     */
    readonly accountId: string

    /**
     * The information required to reset the password
     * @type {AccountPasswordResetKey}
     * @memberof CustomerAccountApiResetAccountPassword
     */
    readonly accountPasswordResetKey: AccountPasswordResetKey

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerAccountApiResetAccountPassword
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for updateCustomerAccount operation in CustomerAccountApi.
 * @export
 * @interface CustomerAccountApiUpdateCustomerAccountRequest
 */
export interface CustomerAccountApiUpdateCustomerAccountRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountApiUpdateCustomerAccount
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountApiUpdateCustomerAccount
     */
    readonly accountId: string

    /**
     * The update request
     * @type {UpdateCustomerAccountRequest}
     * @memberof CustomerAccountApiUpdateCustomerAccount
     */
    readonly updateCustomerAccountRequest: UpdateCustomerAccountRequest

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerAccountApiUpdateCustomerAccount
     */
    readonly acceptLanguage?: string
}

/**
 * CustomerAccountApi - object-oriented interface
 * @export
 * @class CustomerAccountApi
 * @extends {BaseAPI}
 */
export class CustomerAccountApi extends BaseAPI {
    /**
     * Activate a new user account
     * @param {CustomerAccountApiActivateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public activateAccount(requestParameters: CustomerAccountApiActivateAccountRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).activateAccount(requestParameters.customerId, requestParameters.accountId, requestParameters.accountActivationRequest, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the password of the customer account. When the reset is for the current account, the current password must be provided as well.
     * @param {CustomerAccountApiChangeAccountPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public changeAccountPassword(requestParameters: CustomerAccountApiChangeAccountPasswordRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).changeAccountPassword(requestParameters.customerId, requestParameters.accountId, requestParameters.passwordChangeRequest, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user account
     * @param {CustomerAccountApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public createAccount(requestParameters: CustomerAccountApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).createAccount(requestParameters.customerId, requestParameters.accountCreateRequest, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a customer account
     * @param {CustomerAccountApiDeleteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public deleteAccount(requestParameters: CustomerAccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).deleteAccount(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve account information
     * @param {CustomerAccountApiGetAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public getAccount(requestParameters: CustomerAccountApiGetAccountRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).getAccount(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the accounts for the customer
     * @param {CustomerAccountApiGetAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public getAccounts(requestParameters: CustomerAccountApiGetAccountsRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).getAccounts(requestParameters.customerId, requestParameters.all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request a new password for the account with the supplied email address
     * @param {CustomerAccountApiRequestPasswordResetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public requestPasswordReset(requestParameters: CustomerAccountApiRequestPasswordResetRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).requestPasswordReset(requestParameters.accountPasswordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset a customer account password with the information retrieved from the password reset email
     * @param {CustomerAccountApiResetAccountPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public resetAccountPassword(requestParameters: CustomerAccountApiResetAccountPasswordRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).resetAccountPassword(requestParameters.customerId, requestParameters.accountId, requestParameters.accountPasswordResetKey, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the customer account status and roles
     * @param {CustomerAccountApiUpdateCustomerAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountApi
     */
    public updateCustomerAccount(requestParameters: CustomerAccountApiUpdateCustomerAccountRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountApiFp(this.configuration).updateCustomerAccount(requestParameters.customerId, requestParameters.accountId, requestParameters.updateCustomerAccountRequest, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

