import {type AxiosInstance, type AxiosResponse} from 'axios';
import {type OidcClientSettings} from 'oidc-client-ts';

interface AppConfigurationData {
  apiEndpoint?: string;
  postalCodeCheckEmbedUrl?: string;
  ssoAuthority?: string;
  ssoClientId?: string;
  ssoManageUrl?: string;
}

export class AppConfiguration {
  private configuration: AppConfigurationData | null = null;

  public async loadConfiguration($http: AxiosInstance): Promise<void> {
    try {
      this.configuration = (await $http.get<never, AxiosResponse<AppConfigurationData>>('/config.json')).data;
    } catch {
      throw new Error('Failed to load configuration data');
    }
  }

  public getApiEndpoint(): string {
    return this.testAvailable().apiEndpoint ?? 'https://api.a2b-internet.com';
  }

  public getPostalCodeCheckEmbedUrl(): string {
    return this.testAvailable().postalCodeCheckEmbedUrl ??
      'https://postcodev4.a2b-internet.com/?name=A2B+Internet&phone=&email=info%40a2b-internet.com';
  }

  public getSsoDetails(): Pick<OidcClientSettings, 'authority' | 'client_id'> {
    const data = this.testAvailable();

    if (!data.ssoAuthority || !data.ssoClientId) {
      throw new Error('SSO not fully configured');
    }

    return {
      authority: data.ssoAuthority,
      client_id: data.ssoClientId,
    };
  }

  public getSsoManageUrl(): string {
    return this.testAvailable().ssoManageUrl ?? '#';
  }

  private testAvailable(): AppConfigurationData {
    if (this.configuration === null) {
      throw new Error('Application configuration not loaded!');
    }

    return this.configuration;
  }
}
