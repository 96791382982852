/* tslint:disable */
/* eslint-disable */
/**
 * 101010 order API and account API
 * Handles order API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@101010.tv
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Account } from '../model';
// @ts-ignore
import type { AccountCreateRequest } from '../model';
// @ts-ignore
import type { AccountCreateResult } from '../model';
// @ts-ignore
import type { AccountList } from '../model';
// @ts-ignore
import type { AccountStatus } from '../model';
// @ts-ignore
import type { BadRequest } from '../model';
// @ts-ignore
import type { SetPinRequest } from '../model';
// @ts-ignore
import type { SetPinResult } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activateAccount', 'id', id)
            const localVarPath = `/v1/account/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {number} [direct] Perform an action directly without queuing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAccount: async (id: number, direct?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelAccount', 'id', id)
            const localVarPath = `/v1/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (direct !== undefined) {
                localVarQueryParameter['direct'] = direct;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * When a cancellation was requested, it may still be possible to cancel/undo the pending account cancellation if not performed yet. 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCancellation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelCancellation', 'id', id)
            const localVarPath = `/v1/account/{id}/cancelCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountCreateRequest} accountCreateRequest Account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (accountCreateRequest: AccountCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountCreateRequest' is not null or undefined
            assertParamExists('createAccount', 'accountCreateRequest', accountCreateRequest)
            const localVarPath = `/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccount', 'id', id)
            const localVarPath = `/v1/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of accounts
         * @param {number} [minAccountNr] The minimum account number to query
         * @param {number} [productCode] The product code to query
         * @param {AccountStatus} [status] Account status to query
         * @param {number} [limit] Query at most this many accounts
         * @param {number} [offset] Skip this many entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (minAccountNr?: number, productCode?: number, status?: AccountStatus, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (minAccountNr !== undefined) {
                localVarQueryParameter['minAccountNr'] = minAccountNr;
            }

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resume a suspended account
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resumeAccount', 'id', id)
            const localVarPath = `/v1/account/{id}/resume`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set a new pin code on the account
         * @param {number} id The account id
         * @param {SetPinRequest} setPinRequest Pin set request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPin: async (id: number, setPinRequest: SetPinRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setPin', 'id', id)
            // verify required parameter 'setPinRequest' is not null or undefined
            assertParamExists('setPin', 'setPinRequest', setPinRequest)
            const localVarPath = `/v1/account/{id}/setPin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendAccount: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('suspendAccount', 'id', id)
            const localVarPath = `/v1/account/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.activateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {number} [direct] Perform an action directly without queuing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAccount(id: number, direct?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAccount(id, direct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.cancelAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * When a cancellation was requested, it may still be possible to cancel/undo the pending account cancellation if not performed yet. 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelCancellation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelCancellation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.cancelCancellation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AccountCreateRequest} accountCreateRequest Account information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(accountCreateRequest: AccountCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountCreateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(accountCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a list of accounts
         * @param {number} [minAccountNr] The minimum account number to query
         * @param {number} [productCode] The product code to query
         * @param {AccountStatus} [status] Account status to query
         * @param {number} [limit] Query at most this many accounts
         * @param {number} [offset] Skip this many entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(minAccountNr?: number, productCode?: number, status?: AccountStatus, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(minAccountNr, productCode, status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Resume a suspended account
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.resumeAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set a new pin code on the account
         * @param {number} id The account id
         * @param {SetPinRequest} setPinRequest Pin set request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPin(id: number, setPinRequest: SetPinRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPinResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPin(id, setPinRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.setPin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspendAccount(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspendAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.suspendAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {AccountApiActivateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateAccount(requestParameters: AccountApiActivateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.activateAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiCancelAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAccount(requestParameters: AccountApiCancelAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelAccount(requestParameters.id, requestParameters.direct, options).then((request) => request(axios, basePath));
        },
        /**
         * When a cancellation was requested, it may still be possible to cancel/undo the pending account cancellation if not performed yet. 
         * @param {AccountApiCancelCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCancellation(requestParameters: AccountApiCancelCancellationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelCancellation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccountCreateResult> {
            return localVarFp.createAccount(requestParameters.accountCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.getAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of accounts
         * @param {AccountApiGetAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(requestParameters: AccountApiGetAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountList> {
            return localVarFp.getAccounts(requestParameters.minAccountNr, requestParameters.productCode, requestParameters.status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Resume a suspended account
         * @param {AccountApiResumeAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeAccount(requestParameters: AccountApiResumeAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resumeAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Set a new pin code on the account
         * @param {AccountApiSetPinRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPin(requestParameters: AccountApiSetPinRequest, options?: RawAxiosRequestConfig): AxiosPromise<SetPinResult> {
            return localVarFp.setPin(requestParameters.id, requestParameters.setPinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountApiSuspendAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendAccount(requestParameters: AccountApiSuspendAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.suspendAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activateAccount operation in AccountApi.
 * @export
 * @interface AccountApiActivateAccountRequest
 */
export interface AccountApiActivateAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiActivateAccount
     */
    readonly id: number
}

/**
 * Request parameters for cancelAccount operation in AccountApi.
 * @export
 * @interface AccountApiCancelAccountRequest
 */
export interface AccountApiCancelAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiCancelAccount
     */
    readonly id: number

    /**
     * Perform an action directly without queuing
     * @type {number}
     * @memberof AccountApiCancelAccount
     */
    readonly direct?: number
}

/**
 * Request parameters for cancelCancellation operation in AccountApi.
 * @export
 * @interface AccountApiCancelCancellationRequest
 */
export interface AccountApiCancelCancellationRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiCancelCancellation
     */
    readonly id: number
}

/**
 * Request parameters for createAccount operation in AccountApi.
 * @export
 * @interface AccountApiCreateAccountRequest
 */
export interface AccountApiCreateAccountRequest {
    /**
     * Account information
     * @type {AccountCreateRequest}
     * @memberof AccountApiCreateAccount
     */
    readonly accountCreateRequest: AccountCreateRequest
}

/**
 * Request parameters for getAccount operation in AccountApi.
 * @export
 * @interface AccountApiGetAccountRequest
 */
export interface AccountApiGetAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiGetAccount
     */
    readonly id: number
}

/**
 * Request parameters for getAccounts operation in AccountApi.
 * @export
 * @interface AccountApiGetAccountsRequest
 */
export interface AccountApiGetAccountsRequest {
    /**
     * The minimum account number to query
     * @type {number}
     * @memberof AccountApiGetAccounts
     */
    readonly minAccountNr?: number

    /**
     * The product code to query
     * @type {number}
     * @memberof AccountApiGetAccounts
     */
    readonly productCode?: number

    /**
     * Account status to query
     * @type {AccountStatus}
     * @memberof AccountApiGetAccounts
     */
    readonly status?: AccountStatus

    /**
     * Query at most this many accounts
     * @type {number}
     * @memberof AccountApiGetAccounts
     */
    readonly limit?: number

    /**
     * Skip this many entries
     * @type {number}
     * @memberof AccountApiGetAccounts
     */
    readonly offset?: number
}

/**
 * Request parameters for resumeAccount operation in AccountApi.
 * @export
 * @interface AccountApiResumeAccountRequest
 */
export interface AccountApiResumeAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiResumeAccount
     */
    readonly id: number
}

/**
 * Request parameters for setPin operation in AccountApi.
 * @export
 * @interface AccountApiSetPinRequest
 */
export interface AccountApiSetPinRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiSetPin
     */
    readonly id: number

    /**
     * Pin set request
     * @type {SetPinRequest}
     * @memberof AccountApiSetPin
     */
    readonly setPinRequest: SetPinRequest
}

/**
 * Request parameters for suspendAccount operation in AccountApi.
 * @export
 * @interface AccountApiSuspendAccountRequest
 */
export interface AccountApiSuspendAccountRequest {
    /**
     * The account id
     * @type {number}
     * @memberof AccountApiSuspendAccount
     */
    readonly id: number
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {AccountApiActivateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public activateAccount(requestParameters: AccountApiActivateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).activateAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiCancelAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public cancelAccount(requestParameters: AccountApiCancelAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).cancelAccount(requestParameters.id, requestParameters.direct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * When a cancellation was requested, it may still be possible to cancel/undo the pending account cancellation if not performed yet. 
     * @param {AccountApiCancelCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public cancelCancellation(requestParameters: AccountApiCancelCancellationRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).cancelCancellation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).createAccount(requestParameters.accountCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of accounts
     * @param {AccountApiGetAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccounts(requestParameters: AccountApiGetAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccounts(requestParameters.minAccountNr, requestParameters.productCode, requestParameters.status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resume a suspended account
     * @param {AccountApiResumeAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public resumeAccount(requestParameters: AccountApiResumeAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).resumeAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set a new pin code on the account
     * @param {AccountApiSetPinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public setPin(requestParameters: AccountApiSetPinRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).setPin(requestParameters.id, requestParameters.setPinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountApiSuspendAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public suspendAccount(requestParameters: AccountApiSuspendAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).suspendAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

