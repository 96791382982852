<template>
  <div class="card">
    <div
      class="card-header"
      :class="headerClass">
      <slot name="header">
        <template v-if="titleIcon">
          <FlexTextIcon :icon="titleIcon">
            <Translated
              v-if="title"
              :msg="title"
              :props="titleProps"/>
          </FlexTextIcon>
        </template>
        <template v-else>
          <Translated
            v-if="title"
            :msg="title"
            :props="titleProps"/>
        </template>
      </slot>
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <slot name="default"/>
    </div>
  </div>
</template>

<script lang="ts">
import FlexTextIcon from '@components/FlexTextIcon.vue';
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {FlexTextIcon, Translated},
  props: {
    headerClass: [String, Object],
    title: String,
    titleIcon: [String, Array],
    titleProps: Object,
  },
});
</script>
