/* tslint:disable */
/* eslint-disable */
/**
 * 101010 order API and account API
 * Handles order API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@101010.tv
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddProductRequest } from '../model';
// @ts-ignore
import type { InstalledProduct } from '../model';
// @ts-ignore
import type { Package } from '../model';
/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {AddProductRequest} [addProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct: async (id: number, addProductRequest?: AddProductRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProduct', 'id', id)
            const localVarPath = `/v1/account/{id}/product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} productId The unique product installation id
         * @param {number} [direct] Perform an action directly without queuing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProduct: async (id: number, productId: string, direct?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelProduct', 'id', id)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('cancelProduct', 'productId', productId)
            const localVarPath = `/v1/account/{id}/product/{productId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (direct !== undefined) {
                localVarQueryParameter['direct'] = direct;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} productId The unique product installation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProductCancellation: async (id: number, productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelProductCancellation', 'id', id)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('cancelProductCancellation', 'productId', productId)
            const localVarPath = `/v1/account/{id}/product/{productId}/cancelCancellation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/101010packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProducts', 'id', id)
            const localVarPath = `/v1/account/{id}/product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id The account id
         * @param {AddProductRequest} [addProductRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProduct(id: number, addProductRequest?: AddProductRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstalledProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProduct(id, addProductRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.addProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} productId The unique product installation id
         * @param {number} [direct] Perform an action directly without queuing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelProduct(id: number, productId: string, direct?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelProduct(id, productId, direct, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.cancelProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {string} productId The unique product installation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelProductCancellation(id: number, productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelProductCancellation(id, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.cancelProductCancellation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackages(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Package>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPackages(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.getPackages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id The account id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstalledProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.getProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductApiAddProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct(requestParameters: ProductApiAddProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<InstalledProduct> {
            return localVarFp.addProduct(requestParameters.id, requestParameters.addProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductApiCancelProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProduct(requestParameters: ProductApiCancelProductRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelProduct(requestParameters.id, requestParameters.productId, requestParameters.direct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductApiCancelProductCancellationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProductCancellation(requestParameters: ProductApiCancelProductCancellationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelProductCancellation(requestParameters.id, requestParameters.productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackages(options?: RawAxiosRequestConfig): AxiosPromise<Array<Package>> {
            return localVarFp.getPackages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductApiGetProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(requestParameters: ProductApiGetProductsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<InstalledProduct>> {
            return localVarFp.getProducts(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addProduct operation in ProductApi.
 * @export
 * @interface ProductApiAddProductRequest
 */
export interface ProductApiAddProductRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ProductApiAddProduct
     */
    readonly id: number

    /**
     * 
     * @type {AddProductRequest}
     * @memberof ProductApiAddProduct
     */
    readonly addProductRequest?: AddProductRequest
}

/**
 * Request parameters for cancelProduct operation in ProductApi.
 * @export
 * @interface ProductApiCancelProductRequest
 */
export interface ProductApiCancelProductRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ProductApiCancelProduct
     */
    readonly id: number

    /**
     * The unique product installation id
     * @type {string}
     * @memberof ProductApiCancelProduct
     */
    readonly productId: string

    /**
     * Perform an action directly without queuing
     * @type {number}
     * @memberof ProductApiCancelProduct
     */
    readonly direct?: number
}

/**
 * Request parameters for cancelProductCancellation operation in ProductApi.
 * @export
 * @interface ProductApiCancelProductCancellationRequest
 */
export interface ProductApiCancelProductCancellationRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ProductApiCancelProductCancellation
     */
    readonly id: number

    /**
     * The unique product installation id
     * @type {string}
     * @memberof ProductApiCancelProductCancellation
     */
    readonly productId: string
}

/**
 * Request parameters for getProducts operation in ProductApi.
 * @export
 * @interface ProductApiGetProductsRequest
 */
export interface ProductApiGetProductsRequest {
    /**
     * The account id
     * @type {number}
     * @memberof ProductApiGetProducts
     */
    readonly id: number
}

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {ProductApiAddProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public addProduct(requestParameters: ProductApiAddProductRequest, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).addProduct(requestParameters.id, requestParameters.addProductRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductApiCancelProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public cancelProduct(requestParameters: ProductApiCancelProductRequest, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).cancelProduct(requestParameters.id, requestParameters.productId, requestParameters.direct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductApiCancelProductCancellationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public cancelProductCancellation(requestParameters: ProductApiCancelProductCancellationRequest, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).cancelProductCancellation(requestParameters.id, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getPackages(options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getPackages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductApiGetProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProducts(requestParameters: ProductApiGetProductsRequest, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProducts(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

