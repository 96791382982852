import {isProd} from '@/utils';
import {EventBus} from '@plugins/injections';
import mitt, {type Emitter} from 'mitt';
import {type App, inject, type InjectionKey} from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppEmitter = Emitter<Record<string | symbol, any>>;

export function useEventbus(busName?: InjectionKey<AppEmitter>): AppEmitter {
  const emitter = inject(busName ?? EventBus);
  if (!emitter) {
    throw new Error('Event bus not available, did you enable the eventbus plugin?');
  }

  return emitter;
}

export type EventbusOptions = {
  bus?: AppEmitter;
  busName?: symbol;
};

export default function(app: App, options?: EventbusOptions): void {
  const bus = options?.bus ?? mitt();
  const busName = options?.busName ?? EventBus;

  if (!isProd()) {
    // Log event from bus when not in prod
    bus.on('*', (type, e) => console.debug(busName, type, e));
  }

  app.provide(busName, bus);
}
