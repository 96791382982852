/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Contact } from '../model';
/**
 * CustomerContactApi - axios parameter creator
 * @export
 */
export const CustomerContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the customer contact of the given type. Not supported for the main contact.
         * @param {string} customerId The customer ID
         * @param {DeleteCustomerContactTypeEnum} type The contact type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerContact: async (customerId: string, type: DeleteCustomerContactTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomerContact', 'customerId', customerId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteCustomerContact', 'type', type)
            const localVarPath = `/v1/customer/{customerId}/contact/{type}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the customer contact of the given type.
         * @param {string} customerId The customer ID
         * @param {PutCustomerContactTypeEnum} type The contact type
         * @param {Contact} contact Contact information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerContact: async (customerId: string, type: PutCustomerContactTypeEnum, contact: Contact, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('putCustomerContact', 'customerId', customerId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('putCustomerContact', 'type', type)
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('putCustomerContact', 'contact', contact)
            const localVarPath = `/v1/customer/{customerId}/contact/{type}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerContactApi - functional programming interface
 * @export
 */
export const CustomerContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the customer contact of the given type. Not supported for the main contact.
         * @param {string} customerId The customer ID
         * @param {DeleteCustomerContactTypeEnum} type The contact type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerContact(customerId: string, type: DeleteCustomerContactTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerContact(customerId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerContactApi.deleteCustomerContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the customer contact of the given type.
         * @param {string} customerId The customer ID
         * @param {PutCustomerContactTypeEnum} type The contact type
         * @param {Contact} contact Contact information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomerContact(customerId: string, type: PutCustomerContactTypeEnum, contact: Contact, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomerContact(customerId, type, contact, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerContactApi.putCustomerContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerContactApi - factory interface
 * @export
 */
export const CustomerContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerContactApiFp(configuration)
    return {
        /**
         * Delete the customer contact of the given type. Not supported for the main contact.
         * @param {CustomerContactApiDeleteCustomerContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerContact(requestParameters: CustomerContactApiDeleteCustomerContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteCustomerContact(requestParameters.customerId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the customer contact of the given type.
         * @param {CustomerContactApiPutCustomerContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomerContact(requestParameters: CustomerContactApiPutCustomerContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<Contact> {
            return localVarFp.putCustomerContact(requestParameters.customerId, requestParameters.type, requestParameters.contact, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteCustomerContact operation in CustomerContactApi.
 * @export
 * @interface CustomerContactApiDeleteCustomerContactRequest
 */
export interface CustomerContactApiDeleteCustomerContactRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerContactApiDeleteCustomerContact
     */
    readonly customerId: string

    /**
     * The contact type
     * @type {'main' | 'billing' | 'order' | 'abuse' | 'support'}
     * @memberof CustomerContactApiDeleteCustomerContact
     */
    readonly type: DeleteCustomerContactTypeEnum
}

/**
 * Request parameters for putCustomerContact operation in CustomerContactApi.
 * @export
 * @interface CustomerContactApiPutCustomerContactRequest
 */
export interface CustomerContactApiPutCustomerContactRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerContactApiPutCustomerContact
     */
    readonly customerId: string

    /**
     * The contact type
     * @type {'main' | 'billing' | 'order' | 'abuse' | 'support'}
     * @memberof CustomerContactApiPutCustomerContact
     */
    readonly type: PutCustomerContactTypeEnum

    /**
     * Contact information
     * @type {Contact}
     * @memberof CustomerContactApiPutCustomerContact
     */
    readonly contact: Contact
}

/**
 * CustomerContactApi - object-oriented interface
 * @export
 * @class CustomerContactApi
 * @extends {BaseAPI}
 */
export class CustomerContactApi extends BaseAPI {
    /**
     * Delete the customer contact of the given type. Not supported for the main contact.
     * @param {CustomerContactApiDeleteCustomerContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContactApi
     */
    public deleteCustomerContact(requestParameters: CustomerContactApiDeleteCustomerContactRequest, options?: RawAxiosRequestConfig) {
        return CustomerContactApiFp(this.configuration).deleteCustomerContact(requestParameters.customerId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the customer contact of the given type.
     * @param {CustomerContactApiPutCustomerContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerContactApi
     */
    public putCustomerContact(requestParameters: CustomerContactApiPutCustomerContactRequest, options?: RawAxiosRequestConfig) {
        return CustomerContactApiFp(this.configuration).putCustomerContact(requestParameters.customerId, requestParameters.type, requestParameters.contact, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const DeleteCustomerContactTypeEnum = {
    Main: 'main',
    Billing: 'billing',
    Order: 'order',
    Abuse: 'abuse',
    Support: 'support'
} as const;
export type DeleteCustomerContactTypeEnum = typeof DeleteCustomerContactTypeEnum[keyof typeof DeleteCustomerContactTypeEnum];
/**
 * @export
 */
export const PutCustomerContactTypeEnum = {
    Main: 'main',
    Billing: 'billing',
    Order: 'order',
    Abuse: 'abuse',
    Support: 'support'
} as const;
export type PutCustomerContactTypeEnum = typeof PutCustomerContactTypeEnum[keyof typeof PutCustomerContactTypeEnum];
