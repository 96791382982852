<template>
  <div class="container">
    <div class="icon text-warning">
      <SimpleIcon
        :icon="['far', 'question-circle']"
        size="4x"/>
    </div>

    <div class="msg">
      Unfortunately, the page you're looking for could not be found.
    </div>
  </div>
</template>

<script lang="ts">
import SimpleIcon from '@components/SimpleIcon.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {SimpleIcon},
});
</script>

<style lang="scss" scoped>
@import 'src/assets/css/variables';

* {
  text-align: center;
}

.icon, .msg {
  margin-bottom: $spacer;
}

img {
  max-height: 2rem;
}
</style>
