/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateCustomerAccountRequest
 */
export interface UpdateCustomerAccountRequest {
    /**
     * The current account status. Will be ignored when the account is pending activation
     * @type {string}
     * @memberof UpdateCustomerAccountRequest
     */
    'status': UpdateCustomerAccountRequestStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCustomerAccountRequest
     */
    'roles': Array<UpdateCustomerAccountRequestRolesEnum>;
}

export const UpdateCustomerAccountRequestStatusEnum = {
    Enabled: 'ENABLED',
    Disabled: 'DISABLED'
} as const;

export type UpdateCustomerAccountRequestStatusEnum = typeof UpdateCustomerAccountRequestStatusEnum[keyof typeof UpdateCustomerAccountRequestStatusEnum];
export const UpdateCustomerAccountRequestRolesEnum = {
    Admin: 'RESELLER_ADMIN',
    Finance: 'RESELLER_FINANCE',
    Order: 'RESELLER_ORDER',
    ServiceDesk: 'RESELLER_SERVICE_DESK'
} as const;

export type UpdateCustomerAccountRequestRolesEnum = typeof UpdateCustomerAccountRequestRolesEnum[keyof typeof UpdateCustomerAccountRequestRolesEnum];


