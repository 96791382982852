import {useEventbus} from '@plugins/eventbus/eventbus';

export const PageBusyEvent = Symbol('[event]: page busy');
export const PageDoneEvent = Symbol('[event]: page done');
export const PageTitleEvent = Symbol('[event]: page title');

export function usePageBusyWrapper<T>(): (callback: () => T | Promise<T>) => Promise<T | Promise<T>> {
  const eventBus = useEventbus();

  return async (callback: () => T | Promise<T>) => {
    eventBus.emit(PageBusyEvent);
    try {
      return await callback();
    } finally {
      eventBus.emit(PageDoneEvent);
    }
  };
}
