import {type Customer, CustomerApi} from '@apispec/customer';
import {GenerationApi, SSOInteractionApi, ValidationApi} from '@apispec/token';
import {useConfiguration} from '@plugins/config/config';
import {AuthInstance} from '@plugins/injections';
import {type App, type ComputedRef, inject, type Ref} from 'vue';
import {prepareApiInstance} from '../api/api';
import {useEventbus} from '../eventbus/eventbus';
import {useHttp} from '../http/http';
import {useModal} from '../modal/modal';
import Authenticator from './Authenticator';

let apisLoaded = false;

export function useAuthenticator(): Authenticator {
  const authenticator = inject(AuthInstance);
  if (!authenticator) {
    throw new Error('Authenticator not available, did you enable the auth plugin?');
  }

  if (!apisLoaded) {
    authenticator.loadApis(
      prepareApiInstance(SSOInteractionApi),
      prepareApiInstance(GenerationApi),
      prepareApiInstance(ValidationApi),
      prepareApiInstance(CustomerApi),
    );
    apisLoaded = true;
  }

  return authenticator;
}

export function useCurrentAccount(): Ref<Customer | null> {
  return useAuthenticator().account;
}

export function useAccountGuid(): ComputedRef<string | undefined> {
  return useAuthenticator().accountGuid;
}

export function useCustomerGuid(): ComputedRef<string | undefined> {
  return useAuthenticator().customerGuid;
}

export function useIsLoyaltyParticipant(): ComputedRef<boolean> {
  return useAuthenticator().isLoyaltyParticipant;
}

export function useIsAuthenticated(): Ref<boolean> {
  return useAuthenticator().isAuthenticated;
}

export default function(app: App): void {
  app.runWithContext(() => {
    app.provide(AuthInstance, new Authenticator({
      defaultHttp: useHttp(),
      eventBus: useEventbus(),
      modals: useModal(),
      appConfiguration: useConfiguration()
    }));
  });
}
