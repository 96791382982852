/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Address } from '../model';
// @ts-ignore
import type { BadRequest } from '../model';
// @ts-ignore
import type { Customer } from '../model';
// @ts-ignore
import type { CustomerCreateRequest } from '../model';
// @ts-ignore
import type { CustomerCustomInvoiceReferenceUpdateRequest } from '../model';
// @ts-ignore
import type { CustomerIbanUpdateRequest } from '../model';
// @ts-ignore
import type { ResourceId } from '../model';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new customer
         * @param {CustomerCreateRequest} customerCreateRequest Customer information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customerCreateRequest: CustomerCreateRequest, acceptLanguage?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerCreateRequest' is not null or undefined
            assertParamExists('createCustomer', 'customerCreateRequest', customerCreateRequest)
            const localVarPath = `/v1/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the customer information
         * @param {string} customerId The customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomer', 'customerId', customerId)
            const localVarPath = `/v1/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer address information
         * @param {string} customerId The customer ID
         * @param {Address} body The information required to update the address information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAddress: async (customerId: string, body: Address, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerAddress', 'customerId', customerId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCustomerAddress', 'body', body)
            const localVarPath = `/v1/customer/{customerId}/address`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer custom invoice reference
         * @param {string} customerId The customer ID
         * @param {CustomerCustomInvoiceReferenceUpdateRequest} customerCustomInvoiceReferenceUpdateRequest The information required to update the custom invoice reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerCustomInvoiceReference: async (customerId: string, customerCustomInvoiceReferenceUpdateRequest: CustomerCustomInvoiceReferenceUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerCustomInvoiceReference', 'customerId', customerId)
            // verify required parameter 'customerCustomInvoiceReferenceUpdateRequest' is not null or undefined
            assertParamExists('updateCustomerCustomInvoiceReference', 'customerCustomInvoiceReferenceUpdateRequest', customerCustomInvoiceReferenceUpdateRequest)
            const localVarPath = `/v1/customer/{customerId}/custom-invoice-reference`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCustomInvoiceReferenceUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update customer banking information
         * @param {string} customerId The customer ID
         * @param {CustomerIbanUpdateRequest} customerIbanUpdateRequest The information required to update the banking information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerIban: async (customerId: string, customerIbanUpdateRequest: CustomerIbanUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerIban', 'customerId', customerId)
            // verify required parameter 'customerIbanUpdateRequest' is not null or undefined
            assertParamExists('updateCustomerIban', 'customerIbanUpdateRequest', customerIbanUpdateRequest)
            const localVarPath = `/v1/customer/{customerId}/iban`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerIbanUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new customer
         * @param {CustomerCreateRequest} customerCreateRequest Customer information
         * @param {string} [acceptLanguage] Sets the language for error messages (default en)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customerCreateRequest: CustomerCreateRequest, acceptLanguage?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customerCreateRequest, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.createCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the customer information
         * @param {string} customerId The customer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(customerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.getCustomer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer address information
         * @param {string} customerId The customer ID
         * @param {Address} body The information required to update the address information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerAddress(customerId: string, body: Address, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerAddress(customerId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomerAddress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer custom invoice reference
         * @param {string} customerId The customer ID
         * @param {CustomerCustomInvoiceReferenceUpdateRequest} customerCustomInvoiceReferenceUpdateRequest The information required to update the custom invoice reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerCustomInvoiceReference(customerId: string, customerCustomInvoiceReferenceUpdateRequest: CustomerCustomInvoiceReferenceUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerCustomInvoiceReference(customerId, customerCustomInvoiceReferenceUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomerCustomInvoiceReference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update customer banking information
         * @param {string} customerId The customer ID
         * @param {CustomerIbanUpdateRequest} customerIbanUpdateRequest The information required to update the banking information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerIban(customerId: string, customerIbanUpdateRequest: CustomerIbanUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerIban(customerId, customerIbanUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.updateCustomerIban']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Create a new customer
         * @param {CustomerApiCreateCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(requestParameters: CustomerApiCreateCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResourceId> {
            return localVarFp.createCustomer(requestParameters.customerCreateRequest, requestParameters.acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the customer information
         * @param {CustomerApiGetCustomerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(requestParameters: CustomerApiGetCustomerRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.getCustomer(requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer address information
         * @param {CustomerApiUpdateCustomerAddressRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAddress(requestParameters: CustomerApiUpdateCustomerAddressRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.updateCustomerAddress(requestParameters.customerId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer custom invoice reference
         * @param {CustomerApiUpdateCustomerCustomInvoiceReferenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerCustomInvoiceReference(requestParameters: CustomerApiUpdateCustomerCustomInvoiceReferenceRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.updateCustomerCustomInvoiceReference(requestParameters.customerId, requestParameters.customerCustomInvoiceReferenceUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update customer banking information
         * @param {CustomerApiUpdateCustomerIbanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerIban(requestParameters: CustomerApiUpdateCustomerIbanRequest, options?: RawAxiosRequestConfig): AxiosPromise<Customer> {
            return localVarFp.updateCustomerIban(requestParameters.customerId, requestParameters.customerIbanUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiCreateCustomerRequest
 */
export interface CustomerApiCreateCustomerRequest {
    /**
     * Customer information
     * @type {CustomerCreateRequest}
     * @memberof CustomerApiCreateCustomer
     */
    readonly customerCreateRequest: CustomerCreateRequest

    /**
     * Sets the language for error messages (default en)
     * @type {string}
     * @memberof CustomerApiCreateCustomer
     */
    readonly acceptLanguage?: string
}

/**
 * Request parameters for getCustomer operation in CustomerApi.
 * @export
 * @interface CustomerApiGetCustomerRequest
 */
export interface CustomerApiGetCustomerRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerApiGetCustomer
     */
    readonly customerId: string
}

/**
 * Request parameters for updateCustomerAddress operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerAddressRequest
 */
export interface CustomerApiUpdateCustomerAddressRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerApiUpdateCustomerAddress
     */
    readonly customerId: string

    /**
     * The information required to update the address information
     * @type {Address}
     * @memberof CustomerApiUpdateCustomerAddress
     */
    readonly body: Address
}

/**
 * Request parameters for updateCustomerCustomInvoiceReference operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerCustomInvoiceReferenceRequest
 */
export interface CustomerApiUpdateCustomerCustomInvoiceReferenceRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerApiUpdateCustomerCustomInvoiceReference
     */
    readonly customerId: string

    /**
     * The information required to update the custom invoice reference
     * @type {CustomerCustomInvoiceReferenceUpdateRequest}
     * @memberof CustomerApiUpdateCustomerCustomInvoiceReference
     */
    readonly customerCustomInvoiceReferenceUpdateRequest: CustomerCustomInvoiceReferenceUpdateRequest
}

/**
 * Request parameters for updateCustomerIban operation in CustomerApi.
 * @export
 * @interface CustomerApiUpdateCustomerIbanRequest
 */
export interface CustomerApiUpdateCustomerIbanRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerApiUpdateCustomerIban
     */
    readonly customerId: string

    /**
     * The information required to update the banking information
     * @type {CustomerIbanUpdateRequest}
     * @memberof CustomerApiUpdateCustomerIban
     */
    readonly customerIbanUpdateRequest: CustomerIbanUpdateRequest
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Create a new customer
     * @param {CustomerApiCreateCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public createCustomer(requestParameters: CustomerApiCreateCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).createCustomer(requestParameters.customerCreateRequest, requestParameters.acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the customer information
     * @param {CustomerApiGetCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomer(requestParameters: CustomerApiGetCustomerRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomer(requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer address information
     * @param {CustomerApiUpdateCustomerAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerAddress(requestParameters: CustomerApiUpdateCustomerAddressRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomerAddress(requestParameters.customerId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer custom invoice reference
     * @param {CustomerApiUpdateCustomerCustomInvoiceReferenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerCustomInvoiceReference(requestParameters: CustomerApiUpdateCustomerCustomInvoiceReferenceRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomerCustomInvoiceReference(requestParameters.customerId, requestParameters.customerCustomInvoiceReferenceUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update customer banking information
     * @param {CustomerApiUpdateCustomerIbanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerIban(requestParameters: CustomerApiUpdateCustomerIbanRequest, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).updateCustomerIban(requestParameters.customerId, requestParameters.customerIbanUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

