<template>
  <div
    class="form-group"
    :class="formClass">
    <div
      v-if="!!label"
      class="grid-form-label">
      <slot name="label">
        <label
          class="form-label"
          :for="guid">
          <Translated :msg="label"/>
        </label>
      </slot>
    </div>
    <div class="grid-form-field">
      <slot>
        <input
          v-if="type === 'file'"
          :id="guid"
          ref="inputField"
          :accept="acceptFileType"
          class="form-control"
          :class="fieldClass"
          :disabled="disabled"
          :name="name"
          type="file"
          v-on="validationListeners">
        <input
          v-else
          :id="guid"
          ref="inputField"
          :autocomplete="autocomplete"
          :autofocus="autofocus"
          class="form-control"
          :class="fieldClass"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder ? trans(placeholder, placeholderProps) : undefined"
          :type="type"
          :value="inputValue"
          v-on="validationListeners">
      </slot>
    </div>
    <div class="grid-form-help">
      <slot name="help">
        <div
          v-if="help"
          class="help-message">
          <Translated
            :msg="help"
            :props="helpProps"/>
        </div>
      </slot>
    </div>
    <div class="grid-form-errors">
      <slot name="validation-errors">
        <ValidationMessages :errors="errors"/>
        <ValidationMessages
          :errors="relevantApiErrors"
          no-trans/>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import {type ValidationError} from '@plugins/api/api';
import {type ApiError} from '@plugins/api/types';
import Translated from '@plugins/i18n/components/Translated.vue';
import {useTrans} from '@plugins/i18n/i18n';
import {v4} from 'uuid';
import {useField} from 'vee-validate';
import {computed, defineComponent, onMounted, type PropType, ref, watch} from 'vue';
import ValidationMessages from './ValidationMessages.vue';

export default defineComponent({
  components: {Translated, ValidationMessages},
  props: {
    acceptFileType: String,
    apiErrorPath: String,
    apiErrors: {
      type: Array as PropType<(ApiError | ValidationError)[]>,
      default: () => [],
    },
    autocomplete: String,
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    lazy: Boolean,
    initialValue: [String, Number],
    convertToNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: String,
    placeholderProps: Object,
    help: String,
    helpProps: Object,
    stacked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props) {
    const inputField = ref<HTMLInputElement>();
    const {
      value: inputValue,
      errors,
      handleBlur,
      handleChange,
    } = useField(props.name, undefined, {
      initialValue: props.initialValue,
      validateOnValueUpdate: false,
      type: props.type === 'checkbox' || props.type === 'radio' ? props.type : 'default',
    });

    const validationListeners = computed(() => {
      return {
        blur: (e: Event | unknown) => {
          if (props.type === 'date' && !inputValue.value && inputField.value) {
            inputField.value.valueAsDate = null;
          }

          if (!props.lazy || errors.value.length > 0) {
            handleChange(e);
          }
          handleBlur();
        },
        change: handleChange,
        input: (e: Event | unknown) => handleChange(e, errors.value.length > 0),
      };
    });

    watch(inputValue, () => {
      if (props.convertToNumber && typeof inputValue.value !== 'number') {
        const numberValue = parseFloat(inputValue.value);
        if (!isNaN(numberValue)) {
          inputValue.value = numberValue;
        }
      }
    });

    onMounted(() => {
      if (props.autofocus && inputField.value) {
        inputField.value.focus();
      }
    });

    const relevantApiErrors = computed(() => props.apiErrors
      .filter((e) => {
        if (props.apiErrorPath) {
          return e.path === props.apiErrorPath;
        }

        return e.path === props.name;
      })
      .map((e) => e.message));

    return {
      inputValue,
      validationListeners,
      errors,
      inputField,
      guid: v4(),
      trans: useTrans(),
      formClass: computed(() => props.stacked || !props.label ? 'no-grid' : ''),
      fieldClass: computed(() =>
        errors.value.length !== 0 || relevantApiErrors.value.length !== 0 ? 'is-invalid' : ''
      ),
      relevantApiErrors,
    };
  },
  methods: {
    focus() {
      if (!this.inputField) {
        return;
      }

      this.inputField.focus();
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'src/assets/css/variables';

.help-message {
  margin-top: map-get($spacers, 1);
  padding: 0 $input-padding-x;
}
</style>
