<template>
  <div class="simple-icon-container">
    <font-awesome-icon
      :key="version"
      :fixed-width="!noFixedWidth"
      :icon="icon"
      :spin="spin"
      v-bind="$attrs"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue';

export default defineComponent({
  name: 'SimpleIcon',
  props: {
    icon: {
      type: [String, Array],
      required: true,
    },
    spin: Boolean,
    noFixedWidth: Boolean,
  },
  setup(props) {
    const version = ref(0);
    watch(props, () => version.value++, {deep: true});

    return {
      version,
    };
  },
});
</script>
