function getLocalStorage(): Storage {
  if (!window.localStorage) {
    throw new Error('LocalStorage not available');
  }

  return window.localStorage;
}

export function getFromLocalStorage(key: string): string | null {
  try {
    return getLocalStorage().getItem(key);
  } catch (e) {
    console.warn('LocalStorage failed', e);

    return null;
  }
}

export function setInLocalStorage(key: string, value: string): void {
  try {
    getLocalStorage().setItem(key, value);
  } catch (e) {
    console.warn('LocalStorage failed', e);
  }
}

export function removeFromLocalStorage(key: string): void {
  try {
    getLocalStorage().removeItem(key);
  } catch (e) {
    console.warn('LocalStorage failed', e);
  }
}
