import {I18nCurrentLocale, I18nLocalesLoading, I18nSetLocale, I18nTranslator} from '@plugins/injections';
import {determineAppVersion} from '@/utils';
import type {LocalesLoading, SetLocaleFunction} from '@plugins/i18n/types';
import {type App, inject, readonly, type Ref, ref} from 'vue';
import {setInLocalStorage} from '../localStorage';
import {useModal} from '../modal/modal';
import Translator, {TranslatorLastLocaleLocalStorageKey, type TranslatorOptions} from './Translator';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $t: Translator['translate'];
  }
}

export function useTranslator(): Translator {
  const translator = inject(I18nTranslator);
  if (!translator) {
    throw new Error('Translator not available, did you enable the i18n plugin?');
  }

  return translator;
}

export function getCurrentLocale(): Ref<string> {
  const locale = inject(I18nCurrentLocale);

  if (!locale) {
    throw new Error('Current locale not available, did you enable the i18n plugin?');
  }

  return locale;
}

export function useLocalesLoading(): LocalesLoading {
  const loading = inject(I18nLocalesLoading);

  if (loading === undefined) {
    throw new Error('Locales loading not available, did you enable the i18n plugin?');
  }

  return loading;
}

export function useSetLocale(): SetLocaleFunction {
  const setLocaleFunction = inject(I18nSetLocale);
  if (!setLocaleFunction) {
    throw new Error('Set locale function not available, did you enable the i18n plugin?');
  }

  return setLocaleFunction;
}

/**
 * Call this function to initially load the best fitting user locale
 * Should be called in the root component to load the locale and add a loader.
 */
export async function loadInitialLocale(): Promise<void> {
  await useSetLocale()(useTranslator().getPreferredUserLocale());
}

/**
 * Utility function to easily call the translator for a template
 */
export function useTrans(): (key: string, params?: Record<string, unknown>) => string {
  return function(key: string, params?: Record<string, unknown>) {
    return useTranslator().translate(key, params, getCurrentLocale().value);
  };
}

export default function(app: App, options?: TranslatorOptions): void {
  if (!options) {
    throw new Error('Options for i18n plugin required!');
  }
  if (!options.localeResolver) {
    throw new Error('LocaleResolver option required for i18n plugin!');
  }

  const modals = app.runWithContext(() => useModal());
  const currentLocale = ref('');
  const localesLoading = ref<string[]>([]);
  const translator = new Translator(options);

  app.config.globalProperties.$t = function(key: string, params?: Record<string, unknown>, locale?: string): string {
    return translator.translate(key, params, locale ?? currentLocale.value);
  };

  app
    .provide(I18nTranslator, translator)
    .provide(I18nCurrentLocale, readonly(currentLocale))
    .provide(I18nLocalesLoading, readonly(localesLoading))
    .provide(I18nSetLocale, async (locale: string) => {
      if (localesLoading.value.includes(locale)) {
        return;
      }

      localesLoading.value.push(locale);
      try {
        await translator.loadLocale(locale);

        // Update current locale after loading
        currentLocale.value = locale;
        setInLocalStorage(TranslatorLastLocaleLocalStorageKey, currentLocale.value);
      } catch {
        try {
          await determineAppVersion(modals);
        } catch {
          modals.generalError();
        }
      } finally {
        // Always clear the loading
        localesLoading.value.splice(localesLoading.value.findIndex((l) => l === locale), 1);
      }
    });
}
