/* tslint:disable */
/* eslint-disable */
/**
 * 101010 order API and account API
 * Handles order API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@101010.tv
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InstalledProductAllOf
 */
export interface InstalledProductAllOf {
    /**
     * Unique id of the installed product
     * @type {string}
     * @memberof InstalledProductAllOf
     */
    'id': string;
    /**
     * Status of installed product
     * @type {string}
     * @memberof InstalledProductAllOf
     */
    'status': InstalledProductAllOfStatusEnum;
    /**
     * Start date of the product
     * @type {string}
     * @memberof InstalledProductAllOf
     */
    'startDate'?: string;
    /**
     * End date of the product
     * @type {string}
     * @memberof InstalledProductAllOf
     */
    'endDate'?: string;
}

export const InstalledProductAllOfStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    Ended: 'ended'
} as const;

export type InstalledProductAllOfStatusEnum = typeof InstalledProductAllOfStatusEnum[keyof typeof InstalledProductAllOfStatusEnum];


