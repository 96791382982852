/* tslint:disable */
/* eslint-disable */
/**
 * A2B Token API
 * Authentication token interaction API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { TokenRequest } from '../model';
// @ts-ignore
import type { TokenResult } from '../model';
/**
 * GenerationApi - axios parameter creator
 * @export
 */
export const GenerationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate a JWT using user credentials. Needs to be refreshed in time.
         * @summary Generate JWT
         * @param {TokenRequest} tokenRequest Generate a token for authentication
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateToken: async (tokenRequest: TokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRequest' is not null or undefined
            assertParamExists('generateToken', 'tokenRequest', tokenRequest)
            const localVarPath = `/v1/token/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenerationApi - functional programming interface
 * @export
 */
export const GenerationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenerationApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate a JWT using user credentials. Needs to be refreshed in time.
         * @summary Generate JWT
         * @param {TokenRequest} tokenRequest Generate a token for authentication
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async generateToken(tokenRequest: TokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateToken(tokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GenerationApi.generateToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GenerationApi - factory interface
 * @export
 */
export const GenerationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenerationApiFp(configuration)
    return {
        /**
         * Generate a JWT using user credentials. Needs to be refreshed in time.
         * @summary Generate JWT
         * @param {GenerationApiGenerateTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        generateToken(requestParameters: GenerationApiGenerateTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenResult> {
            return localVarFp.generateToken(requestParameters.tokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for generateToken operation in GenerationApi.
 * @export
 * @interface GenerationApiGenerateTokenRequest
 */
export interface GenerationApiGenerateTokenRequest {
    /**
     * Generate a token for authentication
     * @type {TokenRequest}
     * @memberof GenerationApiGenerateToken
     */
    readonly tokenRequest: TokenRequest
}

/**
 * GenerationApi - object-oriented interface
 * @export
 * @class GenerationApi
 * @extends {BaseAPI}
 */
export class GenerationApi extends BaseAPI {
    /**
     * Generate a JWT using user credentials. Needs to be refreshed in time.
     * @summary Generate JWT
     * @param {GenerationApiGenerateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof GenerationApi
     */
    public generateToken(requestParameters: GenerationApiGenerateTokenRequest, options?: RawAxiosRequestConfig) {
        return GenerationApiFp(this.configuration).generateToken(requestParameters.tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

