<template>
  <div class="text-center">
    <div class="text-success mb-2">
      <SimpleIcon
        icon="check-circle"
        :size="iconSize"/>
    </div>
    <div>
      <slot name="pre-message"/>
      <p
        class="success-message"
        :class="{'mb-0': compact}">
        <Translated
          :msg="msg"
          :props="msgProps"/>
      </p>
      <slot name="after-message"/>
    </div>
    <div>
      <slot name="button"/>
    </div>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent} from 'vue';
import SimpleIcon from '../SimpleIcon.vue';

export default defineComponent({
  name: 'SuccessMessage',
  components: {SimpleIcon, Translated},
  props: {
    msg: {
      type: String,
      required: true,
    },
    msgProps: Object,
    iconSize: {
      type: String,
      default: '8x',
    },
    compact: {
      type: Boolean,
      default: false,
    }
  },
});
</script>
