<template>
  <div>
    <SuccessMessage
      v-show="logoutDone"
      msg="auth.logout-success">
      <template #button>
        <router-link
          class="btn btn-primary"
          to="/">
          <SimpleIcon icon="sign-in-alt"/>
          <Translated msg="auth.login"/>
        </router-link>
      </template>
    </SuccessMessage>
  </div>
</template>

<script lang="ts">
import {usePageBusyWrapper} from '@/events';
import SuccessMessage from '@components/interaction/SuccessMessage.vue';
import SimpleIcon from '@components/SimpleIcon.vue';
import {useAuthenticator} from '@plugins/auth/auth';
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
  components: {SimpleIcon, SuccessMessage, Translated},
  setup: () => {
    const logoutDone = ref(false);
    const busyWrapper = usePageBusyWrapper();

    onMounted(() => {
      busyWrapper(async () => {
        await useAuthenticator().logout();
        logoutDone.value = true;
      });
    });

    return {
      logoutDone,
    };
  },
});
</script>
