import Messages, {type MessageData} from '@messageformat/runtime/messages';
import {getFromLocalStorage} from '../localStorage';
import * as Sentry from '@sentry/vue';

export const TranslatorLastLocaleLocalStorageKey = '_i18n_translator_last_locale';

export interface TranslatorOptions {
  localeResolver: (locale: string) => Promise<MessageData>;
  defaultLocale?: string;
  supportedLocales?: string[];
}

export default class Translator {
  private messages: Messages;
  private options: {
    localeResolver: (locale: string) => Promise<MessageData>;
    defaultLocale: string;
    supportedLocales: string[];
  };

  constructor(options: TranslatorOptions) {
    this.options = Object.assign({
      defaultLocale: 'en',
      supportedLocales: ['en'],
    }, options);

    this.messages = new Messages({});
  }

  public translate(key: string, params?: Record<string, unknown>, locale?: string): string {
    // Early translation when page is still loading
    if (key === 'loading') {
      if (locale === 'nl') {
        return 'Laden...';
      }

      return 'Loading...';
    }

    if (!key) {
      console.debug('Empty translation key provided', key);

      return '';
    }

    const splitKey = key.split('.');
    if (!this.messages.hasMessage(splitKey, locale)) {
      console.debug('Missing translation key', key);
      Sentry.captureMessage(`Missing translation key: ${key}`);

      return key;
    }

    return this.messages.get(splitKey, params || {}, locale) as string;
  }

  public getPreferredUserLocale(): string {
    // Detect locale preference from the browser and override the default language with it
    let locale = this.options.defaultLocale;
    for (const browserLanguage of navigator.languages) {
      if (this.options.supportedLocales.includes(browserLanguage)) {
        // Full hit
        locale = browserLanguage;
        break;
      } else if (this.options.supportedLocales.includes(browserLanguage.substr(0, 2))) {
        // Try two letter variant
        locale = browserLanguage.substr(0, 2);
        break;
      }
    }
    // Test local storage
    const lastUsedLocale = getFromLocalStorage(TranslatorLastLocaleLocalStorageKey);
    if (lastUsedLocale && this.options.supportedLocales.includes(lastUsedLocale)) {
      locale = lastUsedLocale;
    }

    return locale;
  }

  public async loadLocale(locale: string): Promise<void> {
    if (this.messages.availableLocales.includes(locale)) {
      // Already loaded, nothing to do
      return;
    }

    // Load the required locale
    this.messages.addMessages(await this.options.localeResolver(locale), locale);
  }
}
