<template>
  <Modal
    v-for="(modal) in modals.ok"
    v-bind="modal.options"
    :id="modal.id"
    :key="modal.id"
    type="ok"
    @hidden="destroyModal('ok', modal.id)"/>
  <Modal
    v-for="(modal) in modals.confirm"
    v-bind="modal.options"
    :id="modal.id"
    :key="modal.id"
    type="confirm"
    @hidden="destroyModal('confirm', modal.id)"/>
</template>

<script lang="ts">
import {v4} from 'uuid';
import {defineComponent, reactive} from 'vue';
import {useEventbus} from '@plugins/eventbus/eventbus';
import {type ConfirmModalOptions, type OkModalOptions} from '../modal';
import {ModalConfirmEvent, ModalEventbus, ModalOkEvent} from '../symbols';
import Modal from './Modal.vue';

type ModalList<T> = { id: string; options: T }[];
type ModalTypeList = {
  ok: ModalList<OkModalOptions>;
  confirm: ModalList<ConfirmModalOptions>;
};

export default defineComponent({
  name: 'ModalContainer',
  components: {Modal},
  setup() {
    const modals = reactive<ModalTypeList>({
      ok: [],
      confirm: [],
    });

    // Event handler
    const emitter = useEventbus(ModalEventbus);
    emitter.on(ModalOkEvent, options => {
      modals.ok.push({
        id: v4(),
        options,
      });
    });
    emitter.on(ModalConfirmEvent, options => {
      modals.confirm.push({
        id: v4(),
        options,
      });
    });

    return {
      modals,
      destroyModal: (type: keyof ModalTypeList, id: string) => {
        const idx = modals[type].findIndex((m) => m.id === id);
        if (idx !== -1) {
          modals[type].splice(idx, 1);
        }
      },
    };
  },
});
</script>
