import {Configuration} from '@plugins/injections';
import {type App, inject} from 'vue';
import {AppConfiguration} from './AppConfiguration';

export function useConfiguration(): AppConfiguration {
  const configuration = inject(Configuration);
  if (!configuration) {
    throw new Error('Application configuration not available, did you enable the config plugin?');
  }

  return configuration;
}

export default function(app: App): void {
  app.provide(Configuration, new AppConfiguration());
}
