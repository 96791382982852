/* tslint:disable */
/* eslint-disable */
/**
 * A2B loyalty interface
 * Loyalty programme interaction
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BalanceResponse } from '../model';
// @ts-ignore
import type { LoginResponse } from '../model';
/**
 * LoyaltyApi - axios parameter creator
 * @export
 */
export const LoyaltyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the redirect url for single signon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doLogin: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/loyalty/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the loyalty credit balance for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/loyalty/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyApi - functional programming interface
 * @export
 */
export const LoyaltyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the redirect url for single signon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doLogin(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doLogin(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoyaltyApi.doLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the loyalty credit balance for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalance(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalance(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoyaltyApi.getBalance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoyaltyApi - factory interface
 * @export
 */
export const LoyaltyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyApiFp(configuration)
    return {
        /**
         * Retrieves the redirect url for single signon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doLogin(options?: RawAxiosRequestConfig): AxiosPromise<LoginResponse> {
            return localVarFp.doLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the loyalty credit balance for the current account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(options?: RawAxiosRequestConfig): AxiosPromise<BalanceResponse> {
            return localVarFp.getBalance(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyApi - object-oriented interface
 * @export
 * @class LoyaltyApi
 * @extends {BaseAPI}
 */
export class LoyaltyApi extends BaseAPI {
    /**
     * Retrieves the redirect url for single signon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyApi
     */
    public doLogin(options?: RawAxiosRequestConfig) {
        return LoyaltyApiFp(this.configuration).doLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the loyalty credit balance for the current account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyApi
     */
    public getBalance(options?: RawAxiosRequestConfig) {
        return LoyaltyApiFp(this.configuration).getBalance(options).then((request) => request(this.axios, this.basePath));
    }
}

