/* tslint:disable */
/* eslint-disable */
/**
 * A2B Customer API
 * Internal API for customer management
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@a2b-internet.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CustomerAccountApiToken } from '../model';
// @ts-ignore
import type { CustomerAccountNewApiToken } from '../model';
// @ts-ignore
import type { CustomerAccountTokenDescription } from '../model';
/**
 * CustomerAccountTokensApi - axios parameter creator
 * @export
 */
export const CustomerAccountTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the current API tokens for this customer account
         * @summary Retrieve API tokens
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountApiTokens: async (customerId: string, accountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerAccountApiTokens', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('customerAccountApiTokens', 'accountId', accountId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/token`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new API token for the customer account. With admin rights you can create tokens for any account, otherwise only for your own account.
         * @summary Create API token
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {CustomerAccountTokenDescription} [customerAccountTokenDescription] Token data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountCreateApiToken: async (customerId: string, accountId: string, customerAccountTokenDescription?: CustomerAccountTokenDescription, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerAccountCreateApiToken', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('customerAccountCreateApiToken', 'accountId', accountId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/token`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerAccountTokenDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a existing API token for the customer account. With admin rights you can delete tokens for any account, otherwise only for your own account.
         * @summary Delete API token
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {string} tokenId The API token ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountDeleteApiToken: async (customerId: string, accountId: string, tokenId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerAccountDeleteApiToken', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('customerAccountDeleteApiToken', 'accountId', accountId)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('customerAccountDeleteApiToken', 'tokenId', tokenId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/token/{tokenId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing API token for the customer account. With admin rights you can update tokens for any account, otherwise only for your own account.
         * @summary Patch API token description
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {string} tokenId The API token ID
         * @param {CustomerAccountTokenDescription} [customerAccountTokenDescription] Token data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountPatchApiToken: async (customerId: string, accountId: string, tokenId: string, customerAccountTokenDescription?: CustomerAccountTokenDescription, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerAccountPatchApiToken', 'customerId', customerId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('customerAccountPatchApiToken', 'accountId', accountId)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('customerAccountPatchApiToken', 'tokenId', tokenId)
            const localVarPath = `/v1/customer/{customerId}/account/{accountId}/token/{tokenId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"tokenId"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerAccountTokenDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerAccountTokensApi - functional programming interface
 * @export
 */
export const CustomerAccountTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerAccountTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the current API tokens for this customer account
         * @summary Retrieve API tokens
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccountApiTokens(customerId: string, accountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerAccountApiToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccountApiTokens(customerId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountTokensApi.customerAccountApiTokens']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new API token for the customer account. With admin rights you can create tokens for any account, otherwise only for your own account.
         * @summary Create API token
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {CustomerAccountTokenDescription} [customerAccountTokenDescription] Token data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccountCreateApiToken(customerId: string, accountId: string, customerAccountTokenDescription?: CustomerAccountTokenDescription, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccountNewApiToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccountCreateApiToken(customerId, accountId, customerAccountTokenDescription, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountTokensApi.customerAccountCreateApiToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a existing API token for the customer account. With admin rights you can delete tokens for any account, otherwise only for your own account.
         * @summary Delete API token
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {string} tokenId The API token ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccountDeleteApiToken(customerId: string, accountId: string, tokenId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccountDeleteApiToken(customerId, accountId, tokenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountTokensApi.customerAccountDeleteApiToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing API token for the customer account. With admin rights you can update tokens for any account, otherwise only for your own account.
         * @summary Patch API token description
         * @param {string} customerId The customer ID
         * @param {string} accountId The account ID
         * @param {string} tokenId The API token ID
         * @param {CustomerAccountTokenDescription} [customerAccountTokenDescription] Token data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerAccountPatchApiToken(customerId: string, accountId: string, tokenId: string, customerAccountTokenDescription?: CustomerAccountTokenDescription, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerAccountApiToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerAccountPatchApiToken(customerId, accountId, tokenId, customerAccountTokenDescription, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerAccountTokensApi.customerAccountPatchApiToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerAccountTokensApi - factory interface
 * @export
 */
export const CustomerAccountTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerAccountTokensApiFp(configuration)
    return {
        /**
         * Retrieve the current API tokens for this customer account
         * @summary Retrieve API tokens
         * @param {CustomerAccountTokensApiCustomerAccountApiTokensRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountApiTokens(requestParameters: CustomerAccountTokensApiCustomerAccountApiTokensRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CustomerAccountApiToken>> {
            return localVarFp.customerAccountApiTokens(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new API token for the customer account. With admin rights you can create tokens for any account, otherwise only for your own account.
         * @summary Create API token
         * @param {CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountCreateApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAccountNewApiToken> {
            return localVarFp.customerAccountCreateApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.customerAccountTokenDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a existing API token for the customer account. With admin rights you can delete tokens for any account, otherwise only for your own account.
         * @summary Delete API token
         * @param {CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountDeleteApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.customerAccountDeleteApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing API token for the customer account. With admin rights you can update tokens for any account, otherwise only for your own account.
         * @summary Patch API token description
         * @param {CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerAccountPatchApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomerAccountApiToken> {
            return localVarFp.customerAccountPatchApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.tokenId, requestParameters.customerAccountTokenDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customerAccountApiTokens operation in CustomerAccountTokensApi.
 * @export
 * @interface CustomerAccountTokensApiCustomerAccountApiTokensRequest
 */
export interface CustomerAccountTokensApiCustomerAccountApiTokensRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountApiTokens
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountApiTokens
     */
    readonly accountId: string
}

/**
 * Request parameters for customerAccountCreateApiToken operation in CustomerAccountTokensApi.
 * @export
 * @interface CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest
 */
export interface CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountCreateApiToken
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountCreateApiToken
     */
    readonly accountId: string

    /**
     * Token data
     * @type {CustomerAccountTokenDescription}
     * @memberof CustomerAccountTokensApiCustomerAccountCreateApiToken
     */
    readonly customerAccountTokenDescription?: CustomerAccountTokenDescription
}

/**
 * Request parameters for customerAccountDeleteApiToken operation in CustomerAccountTokensApi.
 * @export
 * @interface CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest
 */
export interface CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountDeleteApiToken
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountDeleteApiToken
     */
    readonly accountId: string

    /**
     * The API token ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountDeleteApiToken
     */
    readonly tokenId: string
}

/**
 * Request parameters for customerAccountPatchApiToken operation in CustomerAccountTokensApi.
 * @export
 * @interface CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest
 */
export interface CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest {
    /**
     * The customer ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountPatchApiToken
     */
    readonly customerId: string

    /**
     * The account ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountPatchApiToken
     */
    readonly accountId: string

    /**
     * The API token ID
     * @type {string}
     * @memberof CustomerAccountTokensApiCustomerAccountPatchApiToken
     */
    readonly tokenId: string

    /**
     * Token data
     * @type {CustomerAccountTokenDescription}
     * @memberof CustomerAccountTokensApiCustomerAccountPatchApiToken
     */
    readonly customerAccountTokenDescription?: CustomerAccountTokenDescription
}

/**
 * CustomerAccountTokensApi - object-oriented interface
 * @export
 * @class CustomerAccountTokensApi
 * @extends {BaseAPI}
 */
export class CustomerAccountTokensApi extends BaseAPI {
    /**
     * Retrieve the current API tokens for this customer account
     * @summary Retrieve API tokens
     * @param {CustomerAccountTokensApiCustomerAccountApiTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountTokensApi
     */
    public customerAccountApiTokens(requestParameters: CustomerAccountTokensApiCustomerAccountApiTokensRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountTokensApiFp(this.configuration).customerAccountApiTokens(requestParameters.customerId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new API token for the customer account. With admin rights you can create tokens for any account, otherwise only for your own account.
     * @summary Create API token
     * @param {CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountTokensApi
     */
    public customerAccountCreateApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountCreateApiTokenRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountTokensApiFp(this.configuration).customerAccountCreateApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.customerAccountTokenDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a existing API token for the customer account. With admin rights you can delete tokens for any account, otherwise only for your own account.
     * @summary Delete API token
     * @param {CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountTokensApi
     */
    public customerAccountDeleteApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountDeleteApiTokenRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountTokensApiFp(this.configuration).customerAccountDeleteApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing API token for the customer account. With admin rights you can update tokens for any account, otherwise only for your own account.
     * @summary Patch API token description
     * @param {CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerAccountTokensApi
     */
    public customerAccountPatchApiToken(requestParameters: CustomerAccountTokensApiCustomerAccountPatchApiTokenRequest, options?: RawAxiosRequestConfig) {
        return CustomerAccountTokensApiFp(this.configuration).customerAccountPatchApiToken(requestParameters.customerId, requestParameters.accountId, requestParameters.tokenId, requestParameters.customerAccountTokenDescription, options).then((request) => request(this.axios, this.basePath));
    }
}

