<template>
  <div
    class="d-flex"
    :class="{'justify-content-end': ltr}">
    <div class="me-2">
      <slot name="icon">
        <SimpleIcon
          v-if="icon"
          :class="iconClass"
          :icon="icon"
          :spin="spin"/>
      </slot>
    </div>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import SimpleIcon from './SimpleIcon.vue';

export default defineComponent({
  components: {SimpleIcon},
  props: {
    ltr: {
      default: false,
      type: Boolean,
    },
    icon: {
      type: [String, Array],
    },
    iconVariant: {
      type: String,
    },
    spin: {
      type: Boolean,
    },
  },
  setup: (props) => {
    return {
      iconClass: computed(() => {
        if (props.iconVariant) {
          return `text-${props.iconVariant}`;
        }

        return '';
      }),
    };
  },
});
</script>
