import {ModalFunctions} from '@plugins/injections';
import {ModalConfirmEvent, ModalEventbus, ModalOkEvent} from '@plugins/modal/symbols';
import mitt from 'mitt';
import {type App, inject} from 'vue';
import eventbus from '../eventbus/eventbus';

interface ModalOptions {
  title: string;
  titleProps?: Record<string, unknown>,
  content: string;
  contentProps?: Record<string, unknown>,
  translate?: boolean;
  modalSize?: 'sm' | 'lg' | 'xl';
  hideCallback?: () => void | boolean;
}

export interface OkModalOptions extends ModalOptions {
  okText?: string;
  okIcon?: string | string[];
  okCallback?: () => void | boolean;
  icon?: string | string[];
  iconVariant?: 'danger' | 'warning' | 'info' | 'success';
}

export interface ConfirmModalOptions extends OkModalOptions {
  cancelText?: string;
  cancelIcon?: string | string[];
  cancelCallback?: () => void | boolean;
}

export interface ModalInterface {
  confirm: (options: ConfirmModalOptions) => void;
  confirmError: (options: ConfirmModalOptions) => void;
  error: (options: OkModalOptions) => void;
  generalError: () => void;
  ok: (options: OkModalOptions) => void;
}

export function useModal(): ModalInterface {
  const functions = inject(ModalFunctions);
  if (!functions) {
    throw new Error('Modal functions not found, did you enable the modal plugin?');
  }

  return functions;
}

export default function(app: App): void {
  // Define the event bus for modals
  const bus = mitt();

  // Make the modal event bus available in the main app
  // We cannot use the eventbus plugin directly, as Vue doesn't load it twice
  eventbus(app, {
    bus,
    busName: ModalEventbus,
  });

  const functions: ModalInterface = {
    confirm: (options) => {
      bus.emit(ModalConfirmEvent, options);
    },
    confirmError: (options) => {
      bus.emit(ModalConfirmEvent, Object.assign({
        icon: 'times-circle',
        iconVariant: 'danger',
      }, options));
    },
    error: (options) => {
      bus.emit(ModalOkEvent, Object.assign({
        icon: 'times-circle',
        iconVariant: 'danger',
      }, options));
    },
    generalError: () => {
      bus.emit(ModalOkEvent, {
        title: 'error.application.title',
        content: 'error.application.text',
        okText: 'button.close',
        okIcon: 'times',
        icon: 'times-circle',
        iconVariant: 'danger',
      });
    },
    ok: (options) => {
      bus.emit(ModalOkEvent, options);
    },
  };

  app
    .provide(ModalFunctions, functions);
}
