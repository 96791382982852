<template>
  <div>
    <div class="d-flex flex-wrap">
      <div>
        <h2>
          <slot>
            <Translated
              :msg="title"
              :props="props"/>
          </slot>
        </h2>
      </div>
      <div class="ms-auto mb-2">
        <slot name="end"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {Translated},
  props: {
    title: {
      type: String,
      default: '',
    },
    props: Object,
  },
});
</script>
