import {ProductApi} from '@apispec/101010tv';
import {ProductNameResolver} from '@plugins/101010tv/ProductNameResolver';
import {prepareApiInstance} from '@plugins/api/api';
import {type App, inject} from 'vue';

export const ProductNameResolverInstance = Symbol('[101010]: productName');

export function useProductNameResolver(): ProductNameResolver {
  const productNameResolver = inject<ProductNameResolver>(ProductNameResolverInstance);
  if (!productNameResolver) {
    throw new Error('ProductNameResolver not available, did you enable the 101010tv plugin?');
  }

  productNameResolver.loadApis(
    prepareApiInstance(ProductApi),
  );

  return productNameResolver;
}

export default function(app: App): void {
  app.provide(ProductNameResolverInstance, new ProductNameResolver());
}
