<template>
  <button
    :class="`btn btn-${variant} ${small ? 'btn-sm' : ''}`"
    :disabled="disabled || loading"
    :type="type">
    <span :style="{opacity: loading ? 0 : 1}">
      <SimpleIcon
        v-if="icon"
        :icon="icon"/>
      <Translated
        :msg="label"
        :props="labelProps"/>
    </span>
    <span
      v-show="loading"
      class="loader">
      <span class="d-flex flex-column justify-content-center h-100">
        <SimpleIcon
          class="mx-auto"
          icon="circle-notch"
          spin/>
      </span>
    </span>
  </button>
</template>

<script lang="ts">
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent, type PropType} from 'vue';
import SimpleIcon from '../SimpleIcon.vue';

export default defineComponent({
  components: {Translated, SimpleIcon},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: [String, Array],
    label: {
      type: String,
      required: true,
    },
    labelProps: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'button'|'submit'|'reset'>,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import 'src/assets/css/mixins/position';

button {
  position: relative;

  .loader {
    @include absolute-full-cover();
  }
}
</style>
