<template>
  <PageTitle title="loyalty.status"/>

  <Loader :loader-state="loaderState">
    <p>
      <Translated
        :msg="viewData.balance ? 'loyalty.balance' : 'loyalty.no-balance'"
        :props="viewData"/>
    </p>

    <div class="d-flex justify-content-end">
      <LoadButton
        icon="shop"
        label="loyalty.login"
        :loading="openShopLoaderState.loading"
        variant="primary"
        @click="openLoyaltyShop"/>
    </div>
  </Loader>
</template>

<script lang="ts">
import {LoyaltyApi} from '@apispec/loyalty';
import LoadButton from '@components/form/LoadButton.vue';
import PageTitle from '@components/layout/PageTitle.vue';
import Loader from '@components/loader/Loader.vue';
import {executeWithLoaderState, LoaderState} from '@components/loader/LoaderState';
import {createApiInstance} from '@plugins/api/api';
import {getResponseObject} from '@plugins/http/httpTools';
import Translated from '@plugins/i18n/components/Translated.vue';
import {defineComponent, onMounted, reactive} from 'vue';

export default defineComponent({
  components: {LoadButton, Translated, Loader, PageTitle},
  setup: () => {
    const api = createApiInstance(LoyaltyApi);
    const loaderState = reactive(new LoaderState());
    const openShopLoaderState = reactive(new LoaderState());
    const viewData = reactive({
      balance: 0,
      forwarding: false,
    });

    onMounted(() => {
      executeWithLoaderState(
        loaderState,
        async () => {
          const balance = await getResponseObject(async () => await api.getBalance());

          if (balance) {
            viewData.balance = balance.balance;
          }
        },
      );
    });

    return {
      loaderState,
      viewData,
      openShopLoaderState,
      openLoyaltyShop: () => {
        executeWithLoaderState(
          openShopLoaderState,
          async () => {
            const login = await getResponseObject(async () => await api.doLogin());

            if (login) {
              window.open(login.loginUrl, '_blank', 'noopener,noreferrer');
            }
          },
        );
      },
    };
  },
});
</script>
