import type Authenticator from '@plugins/auth/Authenticator';
import type {AppConfiguration} from '@plugins/config/AppConfiguration';
import type {AppEmitter} from '@plugins/eventbus/eventbus';
import type {FeatureService} from '@plugins/features/features';
import type Translator from '@plugins/i18n/Translator';
import type {LocalesLoading, SetLocaleFunction} from '@plugins/i18n/types';
import type {ModalInterface} from '@plugins/modal/modal';
import type {AxiosInstance} from 'axios';
import type {InjectionKey, Ref} from 'vue';

export const AuthInstance = Symbol('[auth]: instance') as InjectionKey<Authenticator>;

export const Configuration = Symbol('[config] Configuration') as InjectionKey<AppConfiguration>;

export const EventBus = Symbol('[eventbus]: event bus') as InjectionKey<AppEmitter>;

export const Features = Symbol('[features] Features') as InjectionKey<FeatureService>;

export const HttpInstance = Symbol('[http]: instance') as InjectionKey<AxiosInstance>;
export const HttpDisabled500Handling = Symbol('[http]: disabled 500 handling') as InjectionKey<Ref<string | true | null>>;

export const I18nTranslator = Symbol('[i18n]: translator') as InjectionKey<Translator>;
export const I18nCurrentLocale = Symbol('[i18n]: current locale') as InjectionKey<Ref<string>>;
export const I18nLocalesLoading = Symbol('[i18n]: locales loading') as InjectionKey<LocalesLoading>;
export const I18nSetLocale = Symbol('[i18n]: set locale') as InjectionKey<SetLocaleFunction>;

export const ModalFunctions = Symbol('[modal]: modal functions') as InjectionKey<ModalInterface>;
